import { EditRounded, Print, SearchRounded } from "@mui/icons-material";
import {
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    Modal,
    Box,
    Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
    DataGrid,
    GridActionsCellItem,
    GridToolbarExport,
} from "@mui/x-data-grid";

import { CheckInURL } from "../../Utils/config";
import { get } from "../../Utils/fetcher";
import RoomAction from "./RoomAction.page";
import { useNavigate } from "react-router";

const ViewRoom = () => {
    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState("");
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const handleModalOpen = () => setOpenModal(true);
    const handleModalClose = () => setOpenModal(false);
    const [selectedRow, setSelectedRow] = useState({});
    const [toggle, setToggle] = useState(true);
    const navigate = useNavigate();

    const editButtonHandler = (event, values) => {
        setSelectedRow(values.row);
        handleModalOpen();
    };

    const printButtonHandler = (event, values) => {
        setSelectedRow(values.row);
        navigate("/Room/Book/PrintReceipt", {
            state: { selectedRow: values.row },
        });
    };

    const columns = [
        {
            field: "id",
            headerName: "Booking ID",
        },
        {
            field: "room_number",
            headerName: "Room No",
            valueGetter: (params) => {
                return `${params.row.rooms.room_number}`;
            },
        },
        {
            field: "check_in_date",
            headerName: "Check In Date",
        },
        {
            field: "check_out_date",
            headerName: "Check Out Date",
        },
        {
            field: "number_of_nights",
            headerName: "Number Of Nights",
        },

        {
            field: "first_name",
            headerName: "First Name",
            valueGetter: (params) => {
                return `${params.row.customers.customer_first_name}`;
            },
        },
        {
            field: "last_name",
            headerName: "Last Name",
            valueGetter: (params) => {
                return `${params.row.customers.customer_last_name}`;
            },
        },
        {
            field: "room_type",
            headerName: "Room Type",
            valueGetter: (params) => {
                return `${params.row.rooms.room_type}`;
            },
        },
        {
            field: "mobile_number",
            headerName: "Mobile Number",
            valueGetter: (params) => {
                return `${params.row.customers.customer_mobile}`;
            },
        },
        {
            field: "room_status",
            headerName: "Room Status",
            valueGetter: (params) => {
                return `${params.row.rooms.status}`;
            },
        },
        {
            field: "tenure",
            headerName: "Tenure",
            valueGetter: (params) => {
                return `${params.row.rooms.tenure}`;
            },
        },
        {
            field: "total",
            headerName: "Total",
        },
        {
            field: "actions",
            headerName: "Actions",
            type: "actions",
            minWidth: 150,
            flex: 1,
            getActions: (cellValues) => [
                <GridActionsCellItem
                    icon={<EditRounded color="primary" />}
                    label="Edit"
                    onClick={(event) => {
                        event.stopPropagation();
                        editButtonHandler(event, cellValues);
                    }}
                />,
                <GridActionsCellItem
                    icon={<Print color="primary" />}
                    label="Print"
                    onClick={(event) => {
                        event.stopPropagation();
                        printButtonHandler(event, cellValues);
                    }}
                />,
            ],
        },
    ];

    useEffect(() => {
        if (rows.length === 0) {
            setLoading(true);
        }
        get(CheckInURL).then((res) => {
            setLoading(false);
            if (res.data) {
                let roomsThatNeedAction = res.data.filter(
                    (value) => value.rooms.status !== "Active"
                );

                setRows(roomsThatNeedAction);
            }
        });
    }, [filter, rows.length, toggle]);

    const toggleHandler = () => {
        setToggle(!toggle);
    };

    return (
        <Paper className="Body">
            <Typography variant="h5" fontWeight={"bold"}>
                View Rooms
            </Typography>
            <Box
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <TextField
                    size="small"
                    name="search"
                    label="Search Room"
                    variant="filled"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="start"
                                style={{ marginTop: "-2px" }}
                            >
                                <IconButton
                                    onClick={(e) => {
                                        setFilter(search);
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                    style={{ width: "50%" }}
                />
                <Button
                    variant={"contained"}
                    onClick={() => {
                        navigate("/Room/List/All");
                    }}
                >
                    View All Rooms
                </Button>
            </Box>

            <div style={{ height: 550, width: "100%" }}>
                <DataGrid
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    maxColumns={4}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    onRowClick={(selected) => {
                        // setSelectedRow(selected);
                    }}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "room_status", sort: "asc" }],
                        },
                    }}
                    components={{
                        Toolbar: GridToolbarExport,
                    }}
                    getRowClassName={(params) => {
                        let date = new Date(params.row.check_out_date);
                        if (date === new Date())
                            return `super-app-theme--yellow`;
                        else if (date < new Date())
                            return `super-app-theme--red`;
                    }}
                />
            </div>
            <Modal open={openModal} onClose={handleModalClose}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: "80%",
                        minWidth: "70%",
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <RoomAction
                        selectedRow={selectedRow}
                        toggleHandler={toggleHandler}
                    />
                </Box>
            </Modal>
        </Paper>
    );
};

export default ViewRoom;
