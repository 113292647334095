import React from "react";
import "./App.css";
import SideBar from "./Layout/SideBar.page";
import "./Assets/Sass/index.scss";
import { Route, Routes } from "react-router";
import ViewRoom from "./Pages/Room/ViewRoom.page";
import BookRoom from "./Pages/Room/BookRoom.page";
import CarRent from "./Pages/CarRental/CarRent.page";
import ViewCar from "./Pages/CarRental/ViewCar.page";
import AddAccounting from "./Pages/Accounting/AddAccounting.page";
import ViewAccounting from "./Pages/Accounting/ViewAccounting.page";
import PrintRoom from "./Pages/Room/PrintRoom.page";
import PrintCar from "./Pages/CarRental/PrintCar.page";
import ViewAllRoom from "./Pages/Room/ViewAllRoom.page";
import ViewAllCar from "./Pages/CarRental/ViewAllCar.page";
import PrintRoomAfter from "./Pages/Room/PrintRoomAfter.page";
import PrintCarAfter from "./Pages/CarRental/PrintCarAfter.page";

function App() {
    const routes = [
        {
            path: "/Room/List",
            element: <ViewRoom />,
        },
        {
            path: "/Room/List/All",
            element: <ViewAllRoom />,
        },
        {
            path: "/Room/Book",
            element: <BookRoom />,
        },
        {
            path: "/Room/Book/Print",
            element: <PrintRoom />,
        },
        {
            path: "/Room/Book/PrintReceipt",
            element: <PrintRoomAfter />,
        },
        {
            path: "/Car/List",
            element: <ViewCar />,
        },
        {
            path: "/Car/List/All",
            element: <ViewAllCar />,
        },
        {
            path: "/Car/Rent",
            element: <CarRent />,
        },
        {
            path: "/Car/Rent/Print",
            element: <PrintCar />,
        },
        {
            path: "/Car/Rent/PrintReceipt",
            element: <PrintCarAfter />,
        },
        {
            path: "/Accounting/Add",
            element: <AddAccounting />,
        },
        {
            path: "/Accounting/List",
            element: <ViewAccounting />,
        },
    ];

    return (
        <div className="App">
            <SideBar />

            <Routes>
                {routes.map((route, index) => (
                    <Route
                        path={route.path}
                        element={route.element}
                        key={index}
                    />
                ))}
            </Routes>
        </div>
    );
}

export default App;
