import {
    Box,
    Grid,
    Paper,
    Typography,
    FormControl,
    FormLabel,
    Snackbar,
    Alert,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";

import { AccountingURL } from "../../Utils/config";
import { FileCaller } from "../../Utils/fetcher";
import TextFieldWrapper from "../../Components/TextFieldWrapper.component";
import { enums } from "../../Utils/constants";
import { LoadingButton } from "@mui/lab";

const AddAccounting = () => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [stat, setStat] = useState(false);
    const [msg, setMsg] = useState("");
    const [transactionType, setTransactionType] = useState(1);
    const [paymentType, setPaymentType] = useState("");
    const [bookingType, setBookingType] = useState("");
    const [sourceOfIncome, setSourceOfIncome] = useState("");
    const [typeOfExpense, setTypeOfExpense] = useState("");

    const INITIAL_VALUES = {
        type_of_transaction: transactionType,
        source_of_income: "",
        customer_name: "",
        type_of_expense: "",
        date: "",
        vendor: "",
        payment_type: "",
        booking_type: "",
        amount: "",
        note: "",
    };

    const FORM_VALIDATION = Yup.object().shape({
        type_of_transaction: Yup.string().required("*Required"),
        source_of_income: Yup.string(),
        customer_name: Yup.string(),
        type_of_expense: Yup.string(),
        date: Yup.string().required("*Required"),
        vendor: Yup.string(),
        payment_type: Yup.string(),
        amount: Yup.string().required("*Required"),
    });

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        FileCaller(AccountingURL, data).then((res) => {
            setLoading(false);
            if (res.data?.error === 0) {
                setOpen(true);
                setStat(false);
                setMsg("Error Occured");
            } else if (res?.data?.success === 0) {
                setOpen(true);
                setStat(true);
                setMsg("Statement Saved");
                // actions.resetForm();
            }
        });
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    return (
        <Paper className={"Container"} elevation={0}>
            <Typography variant="h5" fontWeight={"bold"}>
                Add Income/Expenses
            </Typography>
            <Box className={"Container--Outlined"}>
                <Formik
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.type_of_transaction = transactionType;
                        values.payment_type = paymentType;
                        values.booking_type = bookingType;
                        if (values.type_of_transaction === 1) {
                            values.source_of_income =
                                enums.SourceOfIncome[sourceOfIncome - 1];
                        } else if (values.type_of_transaction === 2) {
                            values.type_of_expense =
                                enums.Expenses[typeOfExpense - 1];
                        }
                        /* Then create a new FormData obj */
                        let formData = new FormData();
                        /* append input field values to formData */
                        for (let value in values) {
                            formData.append(value, values[value]);
                        }

                        // for (let property of formData.entries()) {
                        //     console.log(property[0], property[1]);
                        // }

                        formSubmitHandler(formData, actions);
                    }}
                >
                    {(props) => {
                        return (
                            <Form>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <TextFieldWrapper
                                            select
                                            name="type_of_transaction"
                                            label="Type Of Transaction"
                                            variant="outlined"
                                            options={enums.Transaction}
                                            value={transactionType}
                                            onChange={(e) => {
                                                setTransactionType(
                                                    e.target.value
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Box width={"100%"} />
                                    {transactionType === 1 && (
                                        <>
                                            <Grid item xs={4}>
                                                <TextFieldWrapper
                                                    select
                                                    name="source_of_income"
                                                    label="Source Of Income"
                                                    variant="outlined"
                                                    value={sourceOfIncome}
                                                    options={
                                                        enums.SourceOfIncome
                                                    }
                                                    onChange={(e) => {
                                                        setSourceOfIncome(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextFieldWrapper
                                                    name="customer_name"
                                                    label="Customer Name"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextFieldWrapper
                                                    select
                                                    name="booking_type"
                                                    label="Booking Type"
                                                    variant="outlined"
                                                    options={enums.BookingType}
                                                    value={bookingType}
                                                    onChange={(e) => {
                                                        setBookingType(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextFieldWrapper
                                                    select
                                                    name="payment_type"
                                                    label="Payment Type"
                                                    variant="outlined"
                                                    options={enums.PaymentType}
                                                    value={paymentType}
                                                    onChange={(e) => {
                                                        setPaymentType(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    {transactionType === 2 && (
                                        <>
                                            <Grid item xs={4}>
                                                <TextFieldWrapper
                                                    select
                                                    name="type_of_expense"
                                                    label="Type Of Expense"
                                                    variant="outlined"
                                                    value={typeOfExpense}
                                                    options={enums.Expenses}
                                                    onChange={(e) => {
                                                        setTypeOfExpense(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextFieldWrapper
                                                    name="vendor"
                                                    label="Vendor"
                                                    variant="outlined"
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextFieldWrapper
                                                    select
                                                    name="payment_type"
                                                    label="Payment Type"
                                                    variant="outlined"
                                                    options={
                                                        enums.PaymentTypeAccounting
                                                    }
                                                    value={paymentType}
                                                    onChange={(e) => {
                                                        setPaymentType(
                                                            e.target.value
                                                        );
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                    <Box width={"100%"} />
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <FormLabel>Date</FormLabel>
                                            <TextFieldWrapper
                                                name="date"
                                                variant="outlined"
                                                type="date"
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Box width={"100%"} />
                                    <Grid item xs={4}>
                                        <TextFieldWrapper
                                            name="amount"
                                            label="Amount"
                                            variant="outlined"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextFieldWrapper
                                            name="note"
                                            label="Note"
                                            variant="outlined"
                                            multiline
                                            rows={3}
                                        />
                                    </Grid>
                                    <Box width={"100%"} sx={{ mt: 2 }}>
                                        <LoadingButton
                                            variant="contained"
                                            style={{ float: "right" }}
                                            loading={loading}
                                            type="submit"
                                            onClick={() => {
                                                console.log(props.errors);
                                            }}
                                        >
                                            Add
                                        </LoadingButton>
                                    </Box>
                                </Grid>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>

            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    variant={"filled"}
                    onClose={handleClose}
                    severity={stat ? "success" : "error"}
                    sx={{ width: "40ch" }}
                >
                    {msg}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default AddAccounting;
