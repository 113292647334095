import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Cars } from "../../Utils/config";
import { get } from "../../Utils/fetcher";
import Logo from "../../Assets/img/logo.png";
import { LinearProgress, Typography } from "@mui/material";

const PrintCarAfter = () => {
    const { state } = useLocation();
    const { selectedRow } = state;
    const [car, setCar] = useState({});
    const [loading, setloading] = useState(false);

    const values = {
        customer_id: selectedRow.customers?.customer_id,
        customer_first_name: selectedRow.customers?.customer_first_name,
        customer_last_name: selectedRow.customers?.customer_last_name,
        customer_mobile: selectedRow.customers?.customer_mobile,
        customer_email: selectedRow.customers?.customer_email,
        driver_license: selectedRow.customers?.driver_license,
        street: selectedRow.customers?.street,
        city: selectedRow.customers?.city,
        state: selectedRow.customers?.state,
        zip_code: selectedRow.customers?.zip_code,
        car_plate: selectedRow.cars?.car_plate,
        number_of_days: selectedRow.customers?.number_of_days,
        check_out_mileage: selectedRow.check_out_mileage,
        gas_money: selectedRow.customers?.gas_money,
        pick_up_date: selectedRow.pick_up_date,
        return_date: selectedRow.return_date,
        tenure: selectedRow.tenure,
        payment_type: selectedRow.payment_type,
        amount: selectedRow.amount,
        tax: selectedRow.tax,
        total: selectedRow.total,
        car_id: selectedRow.cars?.id,
    };

    useEffect(() => {
        setloading(true);
        get(Cars + "/" + selectedRow.cars?.id).then((res) => {
            setloading(false);
            res.data && setCar({ ...res.data });
        });
    }, [selectedRow.cars?.id]);

    return (
        <div className="CarPrint">
            {loading && <LinearProgress />}
            {!loading && (
                <>
                    <div
                        style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <img
                            src={Logo}
                            alt="OYO Hotel"
                            height={"50ch"}
                            width={"110ch"}
                            style={{ alignSelf: "center" }}
                        />
                        <Box
                            style={{
                                flexDirection: "column",
                                justifyContent: "center",
                                display: "flex",
                            }}
                        >
                            <Typography>
                                <strong>Address </strong>
                                <br />
                                357 S Diision Street,
                                <br />
                                Bliytheville, AR 72315
                            </Typography>
                            <Typography>
                                <strong>Phone </strong>
                                <br />
                                870 763 4588
                            </Typography>
                        </Box>
                    </div>

                    <p className="PrintHeader">RENTAL AGREEMENT OYO HOTEL</p>
                    <hr />

                    <p>
                        This Car Rental Agreement is entered into between Lohit
                        Enterprises LLC (&ldquo;Owner&rdquo;) and
                        <span>
                            {" " +
                                values.customer_first_name +
                                " " +
                                values.customer_last_name}
                        </span>
                        (&ldquo;Renter&rdquo;) (collectively the
                        &ldquo;Parties&rdquo;) and outlines the respective
                        rights and obligations of the Parties relating to the
                        rental of a car.
                    </p>

                    <p className="PrintHeader">
                        1. IDENTIFICATION OF THE RENTAL VEHICLE
                    </p>
                    <p>
                        Owner here by agrees to rent to Renter a passenger
                        vehicle identified as follows:
                    </p>
                    <span>
                        Plate : <span>{car.plate_number}</span>
                    </span>
                    <br />
                    <span>Make: {car.make}</span>
                    <br />
                    <span>Model: {car.model}</span>
                    <br />
                    <span>Year: {car.year}</span>
                    <br />
                    <span>VIN: {car.VIN}</span>
                    <br />
                    <p>
                        Color: {car.color}&#8203; (here in after referred to as
                        &ldquo;Rental Vehicle&rdquo;).
                    </p>
                    <p className="PrintHeader">2. RENTAL TERM</p>

                    <p>
                        The term of this Car Rental Agreement runs from the date
                        and hour of vehicle pickup as indicated just above the
                        signature line at the bottom of this agreement until the
                        return of the vehicle to the Owner, and completion of
                        all terms of this agreement by both Parties. The
                        estimated rental term is as follows:
                    </p>

                    <p>
                        Estimated start date: <span>{values.pick_up_date}</span>
                    </p>

                    <p>
                        Estimated end date: <span>{values.return_date}</span>
                    </p>

                    <p>
                        The Parties may shorten or extend the estimated term of
                        the rental by mutual consent.
                    </p>

                    <p className="PrintHeader">3. SCOPE OF USE</p>
                    <p>
                        The renter will use the Rented Vehicle only for personal
                        or routine business use and operate the Rented Vehicle
                        only on adequately maintained roads and parking lots.
                        Renter will comply with all applicable laws relating to
                        holding licensure to drive the vehicle and the operation
                        of motor vehicles. The renter will not sublease the
                        Rental Vehicle or use it as a vehicle for hire. The
                        renter will not take the vehicle location limit.
                    </p>
                    <table>
                        <tr>
                            <th colspan="3">
                                Renter will not allow any other person to
                                operate the Rented Vehicle unless identified
                                here:
                            </th>
                        </tr>
                        <tr>
                            <td>Primary vehicle operator: </td>
                            <td>DL#{values.customer_name}</td>
                            <td>{values.customer_id}</td>
                        </tr>
                        <tr>
                            <td>Additional vehicle operator: </td>
                            <td>DL#{values.customer_name}</td>
                            <td>_________________________</td>
                        </tr>
                        <tr>
                            <td>Additional vehicle operator </td>
                            <td>DL#{values.customer_name}</td>
                            <td>_________________________</td>
                        </tr>
                    </table>
                    <p className="PrintHeader">4. MILEAGE</p>

                    <p>
                        Mileage of the Rental Vehicle is mileage at the time of
                        commencement of this Car Rental Agreement. Mileage on
                        the vehicle will be limited 200 Miles Any mileage on the
                        vehicle in excess of this limitation will be subject to
                        an excess mileage surcharge of 0.25 per mile.
                    </p>

                    <p className="PrintHeader"> 5. RENTAL FEES</p>

                    <p>
                        Renter will pay to Owner rental fees for use of the
                        Rental Vehicle as follows:
                    </p>

                    <p>
                        Base fee: {values.amount}
                        <br /> {values.number_of_days} day rental including Tax
                        : {values.total} <br />
                        {/* 200 Miles gas : $ 40.00 */}
                    </p>

                    <p>Total : {values.total}</p>

                    <p>Customer Initial x ____________________</p>

                    <p>
                        Fuel: &#8203;25 Cents/Mile &#8203; Excess mileage fees
                        as set forth in Paragraph 4, above.
                    </p>
                    <p className="PrintHeader">6. SECURITY DEPOSIT</p>

                    <p>
                        Renter will be required to provide a security deposit to
                        Owner in the amount of Two Hundreds and fifty dollars
                        (&ldquo;Security Deposit&rdquo;) to be used in the event
                        of loss or damage to the Rental Vehicle during the term
                        of this agreement. Owner may, in lieu of collection of a
                        security deposit, place a hold on a credit card in the
                        same amount. In the event of damage to the Rental
                        Vehicle, Owner will apply this Security Deposit to
                        defray the costs of necessary repairs or replacement. If
                        the cost for repair or replacement of damage to the
                        Rental Vehicle exceeds the amount of the Security
                        Deposit, Renter will be responsible for payment to the
                        Owner of the balance of this cost.
                    </p>

                    <p className="PrintHeader">7. INSURANCE</p>

                    <p>
                        Renter must provide to Owner with proof of insurance
                        that would cover damage to the Rental Vehicle at the
                        time this Car Rental Agreement is signed, as well as
                        personal injury to the Renter, passengers in the Rented
                        Vehicle, and other persons or property. If the Rental
                        Vehicle is damaged or destroyed while it is in the
                        possession of Renter, Renter agrees to pay any required
                        insurance deductible and also assign all rights to
                        collect insurance proceeds to Owner.
                    </p>

                    <p>Customer Initial x_______________________</p>
                    <p className="PrintHeader">8. INDEMNIFICATION</p>

                    <p>
                        Renter agrees to indemnify, defend, and hold harmless
                        the Owner for any loss, damage, or legal actions against
                        Owner as a result of Renter&rsquo;s operation or use of
                        the Rented Vehicle during the term of this Car Rental
                        Agreement. This includes any attorney fees necessarily
                        incurred for these purposes. The renter will also pay
                        for any parking tickets, moving violations, or other
                        citations received while in possession of the Rented
                        Vehicle.
                    </p>

                    <p className="PrintHeader">
                        9. REPRESENTATIONS AND WARRANTIES
                    </p>

                    <p>
                        The owner represents and warrants that to the
                        Owner&rsquo;s knowledge, the Rental Vehicle is in good
                        condition and is safe for the ordinary operation of the
                        vehicle.
                        <br />
                        Renter represents and warrants that Renter is legally
                        entitled to operate a motor vehicle under the laws of
                        this jurisdiction and will not operate it in violation
                        of any laws, or in any negligent or illegal manner.
                        <br />
                        <br />
                        Renter has been given an opportunity to examine the
                        Rental Vehicle in advance of taking possession of it,
                        and upon such inspection, is not aware of any damage
                        existing on the vehicle other than that notated by the
                        separate Existing Damage document.
                        <br />
                    </p>
                    <p className="PrintHeader">10. JURISDICTION AND VENUE</p>

                    <p>
                        In the event of any dispute over this agreement, this
                        Car Rental Agreement will be interpreted by the laws of
                        Mississippi County, AR, and any lawsuit or arbitration
                        must be brought in the corresponding region of
                        Mississippi County, AR. If any portion of this agreement
                        is found to be unenforceable by a court of competent
                        jurisdiction, the remainder of the agreement would still
                        have full force and effect.
                    </p>

                    <p className="PrintHeader">11. ENTIRE AGREEMENT</p>

                    <p>
                        This Car Rental Agreement constitutes the entire
                        agreement between the Parties with respect to this
                        rental arrangement. No modification to this agreement
                        can be made unless in writing signed by both Parties.
                        Any notice required to be given to the other party will
                        be made to the contact information below.
                    </p>

                    <strong>
                        Please check the vehicle condition and notify before
                        taking the car out and NO SMOKING inside the car. If so,
                        customer needs to pay damages of : $ 150.
                    </strong>

                    <p>
                        DATE AND TIME OF VEHICLE PICKUP: <br />
                        {values.pick_up_date}
                        <br />
                    </p>

                    <table style={{ width: "100%" }}>
                        <tr>
                            <th>Lohit Enterprises LLC</th>
                            <th>
                                {values.customer_first_name +
                                    " " +
                                    values.customer_last_name}
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th>{values.customer_id}</th>
                        </tr>
                        <tr>
                            <th></th>
                            <th>Renter</th>
                        </tr>
                        <tr height="100px">
                            <th>_________________________</th>
                            <th>_________________________</th>
                        </tr>
                    </table>
                </>
            )}
        </div>
    );
};

export default PrintCarAfter;
