import PropTypes from "prop-types";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse, List, ListItemButton, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Primary, Dark, White } from "../Utils/color";

export const CollapseMenu = (props) => {
    const [open, setOpen] = useState(props.open || false);
    const [selectedIndex, setSelectedIndex] = useState("");
    const location = useLocation();
    const navigate = useNavigate();

    const handleClick = () => {
        setOpen(!open);
    };

    const handleListItemClick = (event, index, path) => {
        setSelectedIndex(index);
        navigate(path);
    };

    return (
        <div data-testid="CollapseMenu">
            <ListItemButton
                data-testid="collapseButton"
                onClick={handleClick}
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "1.5ch 1ch 1.5ch 1ch",
                    color: Dark,
                }}
            >
                <div style={{ display: "flex", gap: "2ch" }}>
                    {props.icon}
                    <Typography>{props.title}</Typography>
                </div>

                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>

            {props.menus.map((menu, index) => {
                let type = typeof menu === "string" ? true : false;
                return (
                    <Collapse
                        key={index}
                        in={open}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List
                            component="div"
                            disablePadding
                            style={{
                                borderLeft: type && "solid 1px silver",
                                paddingLeft: "2ch",
                            }}
                        >
                            <ListItemButton
                                key={index}
                                data-testid="subMenuButton"
                                selected={
                                    selectedIndex === { menu } &&
                                    new RegExp(
                                        props.pathname[index] +
                                            "$|" +
                                            props.pathname[index] +
                                            "/"
                                    )
                                }
                                onClick={(event) =>
                                    type &&
                                    handleListItemClick(
                                        event,
                                        menu,
                                        props.pathname[index]
                                    )
                                }
                            >
                                <Box
                                    style={{
                                        width: "100%",
                                        backgroundColor:
                                            location.pathname.match(
                                                new RegExp(
                                                    props.pathname[index] +
                                                        "$|" +
                                                        props.pathname[index] +
                                                        "/"
                                                )
                                            ) && type
                                                ? Primary
                                                : null,
                                        borderRadius: "10px",
                                        padding: type && "1ch",
                                        paddingLeft: type && "2ch",
                                    }}
                                >
                                    {type ? (
                                        <Typography
                                            style={{
                                                letterSpacing: "1px",
                                                color:
                                                    location.pathname.match(
                                                        new RegExp(
                                                            props.pathname[
                                                                index
                                                            ] +
                                                                "$|" +
                                                                props.pathname[
                                                                    index
                                                                ] +
                                                                "/"
                                                        )
                                                    ) && type
                                                        ? White
                                                        : Dark,
                                            }}
                                        >
                                            {menu}
                                        </Typography>
                                    ) : (
                                        menu
                                    )}
                                </Box>
                            </ListItemButton>
                        </List>
                    </Collapse>
                );
            })}
        </div>
    );
};

CollapseMenu.propTypes = {
    /**Component of an icon 'Material Ui' */
    icon: PropTypes.element,
    /**list of menus inside the collapse menu */
    menus: PropTypes.array,
    /**Boolean to triger open/close of the collapse menu */
    open: PropTypes.bool,
    /**Path where the menus are navigating */
    pathname: PropTypes.array,
    /**Buttons name */
    title: PropTypes.string.isRequired,
};
