export const enums = {
    RoomType: [
        "1 King Handicap",
        "1 Full Bed",
        "1 King Kitchenette",
        "1 King Bed",
        "1 Full Kitchenette",
        "1 Full Suite",
        "2 Full Bed",
        "2 Full Handicap",
        "2 Full Kitchenette",
    ],
    Tenure: ["Daily", "Weekly", "Monthly"],
    PaymentType: ["Cash", "Cash App", "CC", "OYO"],
    TenureCar: ["Hourly", "Daily", "Weekly"],
    PaymentTypeAccounting: ["Cash", "Check", "CC"],
    BookingType: ["Walkin", "OYO", "Phone"],
    Transaction: ["Income", "Expense"],
    SourceOfIncome: [
        "Hotel",
        "Car Rent",
        "Check Cashing",
        "Laundry",
        "Snacks",
        "Convenient Store",
        "Other",
    ],
    Expenses: [
        "Advertisement",
        "Bank Fees",
        "License Fees",
        "Real Estate Tax",
        "Sales Tax",
        "Internet",
        "Electricity",
        "Gas",
        "Garbage",
        "Housekeeping - Wages",
        "Maintenance - Wages",
        "Front-desk - Wages",
        "Insurance",
        "TV",
        "Supplies - Housekeeping",
        "Supplies - Maintenance",
        "Supplies - Laundry",
        "Supplies - Office",
        "Supplies - Breakfast",
        "Other",
    ],
};
