import { SearchRounded } from "@mui/icons-material";
import {
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    Button,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";

import { CheckInHistoryURL } from "../../Utils/config";
import { get } from "../../Utils/fetcher";
import { Box } from "@mui/system";
import { useNavigate } from "react-router";

const ViewAllRoom = () => {
    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState("");
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const columns = [
        {
            field: "id",
            headerName: "Booking ID",
        },
        {
            field: "room_number",
            headerName: "Room No",
            valueGetter: (params) => {
                return `${params.row.rooms.room_number}`;
            },
        },
        {
            field: "check_in_date",
            headerName: "Check In Date",
        },
        {
            field: "check_out_date",
            headerName: "Check Out Date",
        },
        {
            field: "number_of_nights",
            headerName: "Number Of Nights",
        },

        {
            field: "first_name",
            headerName: "First Name",
            valueGetter: (params) => {
                return `${params.row.customers.customer_first_name}`;
            },
        },
        {
            field: "last_name",
            headerName: "Last Name",
            valueGetter: (params) => {
                return `${params.row.customers.customer_last_name}`;
            },
        },
        {
            field: "room_type",
            headerName: "Room Type",
            valueGetter: (params) => {
                return `${params.row.rooms.room_type}`;
            },
        },
        {
            field: "mobile_number",
            headerName: "Mobile Number",
            valueGetter: (params) => {
                return `${params.row.customers.customer_mobile}`;
            },
        },
        {
            field: "tenure",
            headerName: "Tenure",
            valueGetter: (params) => {
                return `${params.row.rooms.tenure}`;
            },
        },
        {
            field: "total",
            headerName: "Total",
        },
    ];

    useEffect(() => {
        if (rows.length === 0) {
            setLoading(true);
        }
        get(CheckInHistoryURL, { filter }).then((res) => {
            setLoading(false);
            if (res.data) {
                let roomsThatNeedAction = res.data.filter((value) => {
                    if (filter) {
                        return value.rooms?.room_number.match(filter);
                    } else return value;
                });

                setRows(roomsThatNeedAction);
            }
        });
    }, [filter, rows.length]);

    return (
        <Paper className="Body">
            <Typography variant="h5" fontWeight={"bold"}>
                View Rooms
            </Typography>
            <Box
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <TextField
                    size="small"
                    name="search"
                    label="Search Room"
                    variant="filled"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="start"
                                style={{ marginTop: "-2px" }}
                            >
                                <IconButton
                                    onClick={(e) => {
                                        setFilter(search);
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                    style={{ width: "50%" }}
                />
                <Button
                    startIcon={<ChevronLeft />}
                    onClick={() => {
                        navigate("/Room/List");
                    }}
                >
                    Go Back
                </Button>
            </Box>
            <div style={{ height: 500, width: "100%", overflowY: "scroll" }}>
                <DataGrid
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    autoHeight={true}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    onRowClick={(selected) => {
                        // setSelectedRow(selected);
                    }}
                    components={{
                        Toolbar: GridToolbarExport,
                    }}
                />
            </div>
        </Paper>
    );
};

export default ViewAllRoom;
