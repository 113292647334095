import PropTypes from "prop-types";
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { LoadingButton } from "@mui/lab";

export const FormStepper = ({
    steps,
    page,
    finishButton,
    validateFormFunc,
    loading,
    errors,
}) => {
    const [activeStep, setActiveStep] = useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            {activeStep === steps.length ? (
                <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        Do You Want to print blah blah
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleReset}>Reset</Button>
                    </Box>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <Box sx={{ mt: 5, mb: 1 }}>{page[activeStep]}</Box>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Button
                            color="inherit"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            sx={{ mr: 1 }}
                        >
                            Back
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />
                        {activeStep === steps.length - 1 ? (
                            <LoadingButton
                                loading={loading}
                                type="submit"
                                // onClick={() => {
                                //     finishButton();
                                // }}
                            >
                                Add
                            </LoadingButton>
                        ) : (
                            <Button
                                onClick={() => {
                                    validateFormFunc();
                                    if (
                                        errors.customer_id ||
                                        errors.customer_first_name ||
                                        errors.customer_last_name ||
                                        errors.customer_mobile
                                    ) {
                                    } else {
                                        handleNext();
                                        finishButton();
                                    }
                                }}
                            >
                                {activeStep === steps.length - 1
                                    ? "Finish"
                                    : "Next"}
                            </Button>
                        )}
                    </Box>
                </React.Fragment>
            )}
        </Box>
    );
};

FormStepper.propTypes = {
    /**
     * Page Component to be rendered
     */
    page: PropTypes.any,
    /**
     * step names
     */
    steps: PropTypes.arrayOf(PropTypes.string).isRequired,
    finishButton: PropTypes.func,
    loading: PropTypes.bool,
    submitButton: PropTypes.func,
};
