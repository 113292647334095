import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
    Divider,
    FormControl,
    FormLabel,
    Snackbar,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Autocomplete,
    TextField,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FormStepper } from "../../Components/FormStepper.component";
import * as Yup from "yup";

import { CarRentURL, CustomerURL } from "../../Utils/config";
import { FileCaller, get, url } from "../../Utils/fetcher";
import TextFieldWrapper from "../../Components/TextFieldWrapper.component";
import { FileUploadRounded } from "@mui/icons-material";
import { enums } from "../../Utils/constants";
import { DateTimePicker } from "@mui/lab";
import { useNavigate } from "react-router";

const CarRent = () => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [stat, setStat] = useState(false);
    const [msg, setMsg] = useState("");
    const [values, setValues] = useState([]);
    const navigate = useNavigate();

    const uploadRef = useRef();

    const steps = ["Customer Information", "Car Information", "Finish"];
    const INITIAL_VALUES = {
        customer_id: "",
        customer_first_name: "",
        customer_last_name: "",
        customer_mobile: "",
        customer_email: "",
        driver_license: "",
        street: "",
        city: "",
        state: "",
        zip_code: "",
        car_plate: "",
        number_of_days: 1,
        check_out_mileage: "",
        gas_money: "",
        pick_up_date: Date.now(),
        return_date: "",
        tenure: "",
        payment_type: "",
        amount: "",
        tax: 0.26,
        total: "",
        car_id: null,
    };

    const FORM_VALIDATION = Yup.object().shape({
        customer_id: Yup.string().required("*Required"),
        customer_first_name: Yup.string().required("*Required"),
        customer_last_name: Yup.string().required("*Required"),
        customer_mobile: Yup.string().required("*Required"),
        customer_email: Yup.string().email().nullable(),
        check_out_mileage: Yup.string().nullable(),
        number_of_days: Yup.string().required("*Required"),
        gas_money: Yup.string().nullable(),
        pick_up_date: Yup.string().required("*Required"),
        return_date: Yup.string().required("*Required"),
        amount: Yup.string().required("*Required"),
        tenure: Yup.string().nullable(),
        payment_type: Yup.string().nullable(),
        tax: Yup.string().required("*Required"),
        total: Yup.string().required("*Required"),
    });

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        FileCaller(CarRentURL, data).then((res) => {
            setLoading(false);
            if (res.data?.error === 0) {
                setOpen(true);
                setStat(false);
                setMsg(res.data?.msg || "Error Occured");
            } else if (res?.data?.success === 0) {
                setOpen(true);
                setStat(true);
                setMsg(res.data?.msg || "Car Rented");
                setOpenDialog(true);
                // actions.resetForm();
            }
        });
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const handleCloseDialog = (event) => {
        setOpenDialog(false);
    };

    const handlePrint = (file) => {
        navigate("/Car/Rent/Print", { state: { values, file } });
        handleCloseDialog();
    };

    const handleReceipt = (data) => {
        setValues(data);
    };

    //#region === Form Components
    const CustomerInformation = ({
        customerIdFunc,
        customerFirstNameFunc,
        customerLastNameFunc,
        customerMobileFunc,
        customerEmailFunc,
        streetFunc,
        cityFunc,
        stateFunc,
        zip_codeFunc,
        fullCustomerFunction,
        fileFunc,
    }) => {
        const [options, setOptions] = useState([]);
        const [optionsData, setOptionsData] = useState([]);
        const [optionSelect, setOptionSelect] = useState("");
        const [customerValue, setCustomerValue] = useState({
            customer_id: "",
            customer_first_name: "",
            customer_last_name: "",
            customer_mobile: "",
            customer_email: "",
            street: "",
            city: "",
            state: "",
            zip_code: "",
        });
        const [file, setFile] = useState();
        const [fileUrl, setFileUrl] = useState();

        useEffect(() => {
            get(CustomerURL).then((res) => {
                let data = [];
                if (res.data) {
                    res?.data?.forEach((value) => {
                        data?.push(value?.customer_id);
                    });
                    setOptionsData(res.data);
                    setOptions(data);
                }
            });
        }, []);

        useEffect(() => {
            if (optionsData && optionSelect) {
                optionsData.forEach((data) => {
                    if (data.customer_id === optionSelect) {
                        setCustomerValue({
                            customer_id: data.customer_id,
                            customer_first_name: data.customer_first_name,
                            customer_last_name: data.customer_last_name,
                            customer_mobile: data.customer_mobile,
                            customer_email: data.customer_email,
                            street: data.street,
                            city: data.city,
                            state: data.state,
                            zip_code: data.zip_code,
                        });
                        fullCustomerFunction({
                            customer_id: data.customer_id,
                            customer_first_name: data.customer_first_name,
                            customer_last_name: data.customer_last_name,
                            customer_mobile: data.customer_mobile,
                            customer_email: data.customer_email,
                            street: data.street,
                            city: data.city,
                            state: data.state,
                            zip_code: data.zip_code,
                            driver_license: data.driver_license,
                        });
                        setFileUrl(data.driver_license);
                    }
                });
            }
        }, [optionSelect, optionsData]);

        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <Autocomplete
                            loading={options.length < 1 ? true : false}
                            disablePortal
                            options={options}
                            onChange={(event, newValue) => {
                                setOptionSelect(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Search Customer Id"
                                    variant="filled"
                                />
                            )}
                        />
                    </Grid>
                    <Box
                        width={"100%"}
                        height={"2ch"}
                        style={{ borderBottom: "solid silver 1px" }}
                    />

                    <Grid item xs={4}>
                        <TextFieldWrapper
                            value={customerValue.customer_id}
                            name="customer_id"
                            label="ID"
                            variant="outlined"
                            onChange={(e) => {
                                setCustomerValue({
                                    ...customerValue,
                                    customer_id: e.target.value,
                                });
                            }}
                            onBlur={() => {
                                customerIdFunc(customerValue.customer_id);
                            }}
                        />
                    </Grid>
                    <Box width={"100%"} />
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            value={customerValue.customer_first_name}
                            name="customer_first_name"
                            label="First Name"
                            variant="outlined"
                            onChange={(e) => {
                                setCustomerValue({
                                    ...customerValue,
                                    customer_first_name: e.target.value,
                                });
                            }}
                            onBlur={() => {
                                customerFirstNameFunc(
                                    customerValue.customer_first_name
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            value={customerValue.customer_last_name}
                            name="customer_last_name"
                            label="Last Name"
                            variant="outlined"
                            onChange={(e) => {
                                setCustomerValue({
                                    ...customerValue,
                                    customer_last_name: e.target.value,
                                });
                            }}
                            onBlur={() => {
                                customerLastNameFunc(
                                    customerValue.customer_last_name
                                );
                            }}
                        />
                    </Grid>
                    <Box width={"100%"} />
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            value={customerValue.customer_email}
                            name="customer_email"
                            label="E-mail"
                            variant="outlined"
                            type="email"
                            onChange={(e) => {
                                setCustomerValue({
                                    ...customerValue,
                                    customer_email: e.target.value,
                                });
                            }}
                            onBlur={() => {
                                customerEmailFunc(customerValue.customer_email);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            value={customerValue.customer_mobile}
                            name="customer_mobile"
                            label="Mobile"
                            variant="outlined"
                            type="tel"
                            onChange={(e) => {
                                setCustomerValue({
                                    ...customerValue,
                                    customer_mobile: e.target.value,
                                });
                            }}
                            onBlur={() => {
                                customerMobileFunc(
                                    customerValue.customer_mobile
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            value={customerValue.street}
                            name="street"
                            label="Street"
                            variant="outlined"
                            onChange={(e) => {
                                setCustomerValue({
                                    ...customerValue,
                                    street: e.target.value,
                                });
                            }}
                            onBlur={() => {
                                streetFunc(customerValue.street);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            value={customerValue.city}
                            name="city"
                            label="City"
                            variant="outlined"
                            onChange={(e) => {
                                setCustomerValue({
                                    ...customerValue,
                                    city: e.target.value,
                                });
                            }}
                            onBlur={() => {
                                cityFunc(customerValue.city);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            value={customerValue.state}
                            name="state"
                            label="State"
                            variant="outlined"
                            onChange={(e) => {
                                setCustomerValue({
                                    ...customerValue,
                                    state: e.target.value,
                                });
                            }}
                            onBlur={() => {
                                stateFunc(customerValue.state);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            value={customerValue.zip_code}
                            name="zip_code"
                            label="Zip Code"
                            variant="outlined"
                            onChange={(e) => {
                                setCustomerValue({
                                    ...customerValue,
                                    zip_code: e.target.value,
                                });
                            }}
                            onBlur={() => {
                                zip_codeFunc(customerValue.zip_code);
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            data-testid="uploadButton"
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                uploadRef.current.click();
                            }}
                        >
                            Upload
                            {
                                <FileUploadRounded
                                    style={{ paddingLeft: "2ch" }}
                                />
                            }
                        </Button>
                        <input
                            name="file"
                            hidden
                            ref={uploadRef}
                            type="file"
                            accept="image/png,image/jpeg"
                            onChange={(e) => {
                                setFile(e.target.files[0]);
                                fileFunc(e.target.files[0]);
                            }}
                        />
                    </Grid>
                    {(file || fileUrl) && (
                        <Grid item xs={12}>
                            <img
                                onClick={() => {
                                    uploadRef.current.click();
                                }}
                                src={
                                    file
                                        ? URL.createObjectURL(file)
                                        : url + fileUrl
                                }
                                alt=""
                                style={{
                                    maxHeight: "20ch",
                                    maxWidth: "30ch",
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </>
        );
    };
    const CarInformation = ({
        calculateTotal,
        calculateAmount,
        carPlateFunc,
        pickUpDateFunc,
        returnDateFunc,
        amountFunc,
        bookingTypeFunc,
        paymentTypeFunc,
        tenureFunc,
        totalFunc,
    }) => {
        const [carPlate, setCarPlate] = useState("");
        const [availableCars, setAvailableCars] = useState([]);
        const [availableCarPlates, setAvailableCarPlates] = useState([]);
        const [tenure, setTenure] = useState("");
        const [paymentType, setPaymentType] = useState("");
        const [bookingType, setBookingType] = useState("");
        const [pickUpDate, setPickUpDate] = useState(Date.now());
        let date = new Date();
        const [returnDate, setReturnDate] = useState(() => {
            date.setDate(date.getDate() + 1);
            return date;
        });
        const [total, setTotal] = useState(0);
        const [amount, setAmount] = useState(0);

        useEffect(() => {
            let activeCar = [];
            let availableCar = [];
            get("/car").then((res) => {
                res.data.forEach((car) => {
                    if (car.status === "Available") {
                        activeCar.push({
                            plate_number: car.plate_number,
                            car_id: car.id,
                        });
                        availableCar.push(car.plate_number);
                    }
                });
                setAvailableCars(activeCar);
                setAvailableCarPlates(availableCar);
            });
        }, [availableCars.length]);

        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            select
                            name="car_plate"
                            label="Car Plate"
                            variant="outlined"
                            options={availableCarPlates}
                            value={carPlate}
                            onChange={(e) => {
                                setCarPlate(e.target.value);
                            }}
                            onBlur={() => {
                                let id = null;
                                availableCars.forEach((car) => {
                                    if (
                                        car.plate_number ===
                                        availableCarPlates[carPlate - 1]
                                    ) {
                                        id = car.car_id;
                                    }
                                });
                                carPlateFunc(
                                    availableCarPlates[carPlate - 1],
                                    id
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="check_out_mileage"
                            label="Check Out Mileage"
                            variant="outlined"
                            type="number"
                        />
                    </Grid>
                    <Box width={"100%"} />
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            defaultValue={1}
                            name="number_of_days"
                            label="Number Of Days"
                            variant="outlined"
                            type="number"
                            onBlur={(e) => {
                                let retDate = new Date(
                                    pickUpDate +
                                        e.target.value * 24 * 60 * 60 * 1000
                                );
                                setReturnDate(retDate);
                                calculateTotal(setTotal, amount);
                                returnDateFunc(
                                    new Date(retDate)
                                        .toISOString()
                                        .replace(/T|Z|\.\d{3}/g, " ")
                                        .trim()
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="gas_money"
                            label="Gas Money"
                            variant="outlined"
                            type="number"
                        />
                    </Grid>
                    <Box width={"100%"} />
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <FormLabel>Pick Up Date</FormLabel>
                            <DateTimePicker
                                renderInput={(props) => (
                                    <TextFieldWrapper
                                        {...props}
                                        variant="outlined"
                                        name="pick_up_date"
                                        onBlur={() => {
                                            pickUpDateFunc(
                                                new Date(pickUpDate)
                                                    .toISOString()
                                                    .replace(
                                                        /T|Z|\.\d{3}/g,
                                                        " "
                                                    )
                                                    .trim()
                                            );
                                        }}
                                    />
                                )}
                                value={pickUpDate}
                                onChange={(newValue) => {
                                    setPickUpDate(newValue);
                                }}
                                onAccept={() => {
                                    pickUpDateFunc(
                                        new Date(pickUpDate)
                                            .toISOString()
                                            .replace(/T|Z|\.\d{3}/g, " ")
                                            .trim()
                                    );
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <FormLabel>Return Date</FormLabel>
                            <DateTimePicker
                                renderInput={(props) => (
                                    <TextFieldWrapper
                                        {...props}
                                        variant="outlined"
                                        name="return_date"
                                        onBlur={() => {
                                            returnDateFunc(
                                                new Date(returnDate)
                                                    .toISOString()
                                                    .replace(
                                                        /T|Z|\.\d{3}/g,
                                                        " "
                                                    )
                                                    .trim()
                                            );
                                        }}
                                    />
                                )}
                                value={returnDate}
                                onChange={(newValue) => {
                                    setReturnDate(newValue);
                                }}
                                onAccept={() => {
                                    returnDateFunc(
                                        new Date(returnDate)
                                            .toISOString()
                                            .replace(/T|Z|\.\d{3}/g, " ")
                                            .trim()
                                    );
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Box width={"100%"} />
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            value={amount}
                            name="amount"
                            label="Amount"
                            variant="outlined"
                            type="number"
                            onChange={(e) => {
                                setAmount(e.target.value);
                            }}
                            onBlur={() => {
                                calculateTotal(setTotal, amount);
                                amountFunc(amount);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            defaultValue={0.26}
                            name="tax"
                            label="Tax"
                            variant="outlined"
                            type="number"
                            onBlur={() => {
                                calculateTotal(setTotal, amount);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            select
                            name="tenure"
                            label="Tenure"
                            variant="outlined"
                            options={enums.TenureCar}
                            value={tenure}
                            onChange={(e) => {
                                setTenure(e.target.value);
                            }}
                            onBlur={() => {
                                tenureFunc(tenure);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            select
                            name="booking_type"
                            label="Booking Type"
                            variant="outlined"
                            options={enums.BookingType}
                            value={bookingType}
                            onChange={(e) => {
                                setBookingType(e.target.value);
                            }}
                            onBlur={() => {
                                bookingTypeFunc(bookingType);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            select
                            name="payment_type"
                            label="Payment Type"
                            variant="outlined"
                            options={enums.PaymentType}
                            value={paymentType}
                            onChange={(e) => {
                                setPaymentType(e.target.value);
                            }}
                            onBlur={() => {
                                paymentTypeFunc(paymentType);
                            }}
                        />
                    </Grid>
                    <Box width={"100%"} />
                    <Divider variant={"fullWidth"} />
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            value={total}
                            name="total"
                            label="Total"
                            variant="outlined"
                            type="number"
                            onChange={(e) => {
                                setTotal(e.target.value);
                            }}
                            onBlur={() => {
                                calculateAmount(setAmount, total);
                                totalFunc(total);
                                amountFunc(amount);
                            }}
                        />
                    </Grid>
                </Grid>
            </>
        );
    };
    const Finish = () => {
        return (
            <>
                <Box
                    sx={{
                        p: 2,
                        border: "1px dashed grey",
                        borderRadius: "5px",
                    }}
                    id="print-content"
                    className="PrintContainer"
                >
                    <Box className="PrintColumn">
                        <Typography>Customer Information</Typography>
                        <Divider variant="fullWidth" />
                        <Box height={"3ch"} />
                        <Box className="FormValues">
                            <Typography fontWeight={"bold"}>ID -</Typography>
                            <Typography>
                                {values.customer_id ? values.customer_id : "-"}
                            </Typography>
                        </Box>
                        <Box className="FormValues">
                            <Typography fontWeight={"bold"}>
                                First Name -
                            </Typography>
                            <Typography>
                                {values.customer_first_name
                                    ? values.customer_first_name
                                    : "-"}
                            </Typography>
                        </Box>
                        <Box className="FormValues">
                            <Typography fontWeight={"bold"}>
                                Last Name -
                            </Typography>
                            <Typography>
                                {values.customer_last_name
                                    ? values.customer_last_name
                                    : "-"}
                            </Typography>
                        </Box>
                        <Box className="FormValues">
                            <Typography fontWeight={"bold"}>
                                Mobile -
                            </Typography>
                            <Typography>
                                {values.customer_mobile
                                    ? values.customer_mobile
                                    : "-"}
                            </Typography>
                        </Box>
                        <Box className="FormValues">
                            <Typography fontWeight={"bold"}>
                                E-mail -
                            </Typography>
                            <Typography>
                                {values.customer_email
                                    ? values.customer_email
                                    : "-"}
                            </Typography>
                        </Box>
                        <Box className="FormValues">
                            {values.driver_license && (
                                <Grid item xs={12}>
                                    <img
                                        onClick={() => {
                                            uploadRef.current.click();
                                        }}
                                        src={
                                            typeof values.driver_license ===
                                            "string"
                                                ? url + values.driver_license
                                                : URL.createObjectURL(
                                                      values.driver_license
                                                  )
                                        }
                                        alt=""
                                        style={{
                                            maxHeight: "20ch",
                                            maxWidth: "30ch",
                                        }}
                                    />
                                </Grid>
                            )}
                        </Box>
                    </Box>
                    <Divider orientation="vertical" />
                    <Box className="PrintColumn">
                        <Typography>Room Information</Typography>
                        <Divider variant="fullWidth" />
                        <Box height={"3ch"} />
                        <Box className="FormValues">
                            <Typography fontWeight={"bold"}>
                                Car Plate
                            </Typography>

                            <Typography>
                                {values.car_plate ? values.car_plate : "-"}
                            </Typography>
                        </Box>
                        <Box className="FormValues">
                            <Typography fontWeight={"bold"}>
                                Number Of Days
                            </Typography>
                            <Typography>
                                {values.number_of_days
                                    ? values.number_of_days
                                    : "-"}
                            </Typography>
                        </Box>
                        <Box className="FormValues">
                            <Typography fontWeight={"bold"}>
                                Check Out Mileage
                            </Typography>
                            <Typography>
                                {values.check_out_mileage
                                    ? values.check_out_mileage
                                    : "-"}
                            </Typography>
                        </Box>
                        <Box className="FormValues">
                            <Typography fontWeight={"bold"}>
                                Gas Money
                            </Typography>
                            <Typography>
                                {values.gas_money ? values.gas_money : "-"}
                            </Typography>
                        </Box>
                        <Box className="FormValues">
                            <Typography fontWeight={"bold"}>
                                Pick Up Date
                            </Typography>
                            <Typography>
                                {values.pick_up_date
                                    ? values.pick_up_date
                                    : "-"}
                            </Typography>
                        </Box>
                        <Box className="FormValues">
                            <Typography fontWeight={"bold"}>
                                Return Date
                            </Typography>
                            <Typography>
                                {values.return_date ? values.return_date : "-"}
                            </Typography>
                        </Box>
                        <Box className="FormValues">
                            <Typography fontWeight={"bold"}>Tenure</Typography>
                            <Typography>
                                {values.tenure ? values.tenure : "-"}
                            </Typography>
                        </Box>
                        <Box className="FormValues">
                            <Typography fontWeight={"bold"}>
                                Booking Type -
                            </Typography>
                            <Typography>
                                {values.booking_type
                                    ? values.booking_type
                                    : "-"}
                            </Typography>
                        </Box>
                        <Box className="FormValues">
                            <Typography fontWeight={"bold"}>
                                Amount -
                            </Typography>
                            <Typography>
                                {values.amount ? values.amount : "-"}
                            </Typography>
                        </Box>
                        <Box className="FormValues">
                            <Typography fontWeight={"bold"}>Tax -</Typography>
                            <Typography>
                                {values.tax ? values.tax : "-"}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box height={"3ch"} />
                <Divider variant="fullWidth" />
                <Box
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                    }}
                >
                    <Typography fontWeight={"bold"} variant="h5">
                        Total :
                    </Typography>
                    <Typography fontWeight={"bold"} variant="h5">
                        {values.total ? values.total : " -"}
                    </Typography>
                </Box>
            </>
        );
    };
    //#endregion

    return (
        <Paper className={"Container"} elevation={0}>
            <Typography variant="h5" fontWeight={"bold"}>
                Rent Cars
            </Typography>
            <Box className={"Container--Outlined"}>
                <Formik
                    validateOnMount
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.pick_up_date = new Date(values.pick_up_date)
                            .toISOString()
                            .replace(/T|Z|\.\d{3}/g, " ")
                            .trim();
                        values.return_date = new Date(values.return_date)
                            .toISOString()
                            .replace(/T|Z|\.\d{3}/g, " ")
                            .trim();
                        /* Then create a new FormData obj */
                        let formData = new FormData();
                        /* append input field values to formData */
                        for (let value in values) {
                            formData.append(value, values[value]);
                        }

                        // for (let property of formData.entries()) {
                        //     console.log(property[0], property[1]);
                        // }

                        formSubmitHandler(formData, actions);
                    }}
                >
                    {(props) => {
                        const calculateTotal = (setTotal, amount) => {
                            let val = props.values;
                            let taxTotal =
                                amount * val.number_of_days * val.tax;
                            let subTotal =
                                amount * val.number_of_days + taxTotal;
                            setTotal(
                                Math.round((subTotal + Number.EPSILON) * 100) /
                                    100
                            );
                            props.values.total =
                                Math.round((subTotal + Number.EPSILON) * 100) /
                                100;
                        };

                        const calculateAmount = (setAmount, total) => {
                            let val = props.values;
                            let tax = val.tax + 1;
                            let fee = total / tax;
                            let rate = fee / val.number_of_days;
                            setAmount(
                                Math.round(
                                    (parseFloat(rate) + Number.EPSILON) * 100
                                ) / 100
                            );
                            props.values.amount =
                                Math.round((rate + Number.EPSILON) * 100) / 100;
                        };

                        const carPlateFunc = (carPlate, id) => {
                            props.setValues({
                                ...props.values,
                                car_plate: carPlate,
                                car_id: id,
                            });
                        };
                        const pickUpDateFunc = (pickUpDate) => {
                            props.setValues({
                                ...props.values,
                                pick_up_date: pickUpDate,
                            });
                        };
                        const returnDateFunc = (returnDate) => {
                            props.values.return_date = returnDate;
                        };
                        const amountFunc = (amount) => {
                            props.values.amount = parseFloat(amount);
                        };
                        const bookingTypeFunc = (bookingType) => {
                            props.setValues({
                                ...props.values,
                                booking_type: bookingType,
                            });
                        };
                        const paymentTypeFunc = (paymentType) => {
                            props.setValues({
                                ...props.values,
                                payment_type: paymentType,
                            });
                        };
                        const tenureFunc = (tenure) => {
                            props.setValues({
                                ...props.values,
                                tenure: tenure,
                            });
                        };
                        const customerIdFunc = (customer_id) => {
                            props.setValues({
                                ...props.values,
                                customer_id: customer_id,
                            });
                        };
                        const customerFirstNameFunc = (customer_first_name) => {
                            props.setValues({
                                ...props.values,
                                customer_first_name: customer_first_name,
                            });
                        };
                        const customerLastNameFunc = (customer_last_name) => {
                            props.setValues({
                                ...props.values,
                                customer_last_name: customer_last_name,
                            });
                        };
                        const customerMobileFunc = (customer_mobile) => {
                            props.setValues({
                                ...props.values,
                                customer_mobile: customer_mobile,
                            });
                        };
                        const customerEmailFunc = (customer_email) => {
                            props.setValues({
                                ...props.values,
                                customer_email: customer_email,
                            });
                        };
                        const streetFunc = (street) => {
                            props.setValues({
                                ...props.values,
                                street: street,
                            });
                        };
                        const cityFunc = (city) => {
                            props.setValues({
                                ...props.values,
                                city: city,
                            });
                        };
                        const stateFunc = (state) => {
                            props.setValues({
                                ...props.values,
                                state: state,
                            });
                        };
                        const zip_codeFunc = (zip_code) => {
                            props.setValues({
                                ...props.values,
                                zip_code: zip_code,
                            });
                        };

                        const totalFunc = (total) => {
                            props.values.total = parseFloat(total);
                            calculateAmount(null, total);
                        };
                        const fullCustomerFunction = (customerValue) => {
                            props.setValues({
                                ...props.values,
                                ...customerValue,
                            });
                        };

                        const fileFunc = (file) => {
                            props.values.driver_license = file;
                        };
                        return (
                            <Form>
                                <FormStepper
                                    key={1}
                                    loading={loading}
                                    page={[
                                        <CustomerInformation
                                            customerIdFunc={customerIdFunc}
                                            customerFirstNameFunc={
                                                customerFirstNameFunc
                                            }
                                            customerLastNameFunc={
                                                customerLastNameFunc
                                            }
                                            customerMobileFunc={
                                                customerMobileFunc
                                            }
                                            customerEmailFunc={
                                                customerEmailFunc
                                            }
                                            streetFunc={streetFunc}
                                            cityFunc={cityFunc}
                                            stateFunc={stateFunc}
                                            zip_codeFunc={zip_codeFunc}
                                            fullCustomerFunction={
                                                fullCustomerFunction
                                            }
                                            fileFunc={fileFunc}
                                        />,
                                        <CarInformation
                                            calculateTotal={calculateTotal}
                                            calculateAmount={calculateAmount}
                                            carPlateFunc={carPlateFunc}
                                            pickUpDateFunc={pickUpDateFunc}
                                            returnDateFunc={returnDateFunc}
                                            amountFunc={amountFunc}
                                            bookingTypeFunc={bookingTypeFunc}
                                            paymentTypeFunc={paymentTypeFunc}
                                            tenureFunc={tenureFunc}
                                            totalFunc={totalFunc}
                                        />,
                                        <Finish />,
                                    ]}
                                    steps={steps}
                                    finishButton={() => {
                                        if (props.values.number_of_days === 1) {
                                            let date = new Date(
                                                props.values.pick_up_date
                                            );
                                            date.setDate(date.getDate() + 1);
                                            props.setValues({
                                                ...props.values,
                                                return_date: date
                                                    .toISOString()
                                                    .replace(
                                                        /T|Z|\.\d{3}/g,
                                                        " "
                                                    )
                                                    .trim(),
                                            });
                                        } else {
                                            let cod = new Date(
                                                new Date(
                                                    props.values.pick_up_date
                                                ).getTime() +
                                                    props.values
                                                        .number_of_nights *
                                                        24 *
                                                        60 *
                                                        60 *
                                                        1000
                                            );

                                            props.values.return_date = new Date(
                                                cod
                                            )
                                                .toISOString()
                                                .replace(/T|Z|\.\d{3}/g, " ")
                                                .trim();
                                        }
                                        handleReceipt({
                                            ...props.values,
                                            tenure: enums.TenureCar[
                                                props.values.tenure - 1
                                            ],
                                            booking_type:
                                                enums.BookingType[
                                                    props.values.booking_type -
                                                        1
                                                ],
                                            payment_type:
                                                enums.PaymentType[
                                                    props.values.payment_type -
                                                        1
                                                ],
                                            pick_up_date: new Date(
                                                props.values.pick_up_date
                                            )
                                                .toISOString()
                                                .replace(/T|Z|\.\d{3}/g, " ")
                                                .trim(),
                                        });
                                    }}
                                    validateFormFunc={() => {
                                        props.validateForm().then((res) => {
                                            props.setFieldTouched(
                                                "customer_id",
                                                true
                                            );
                                            props.setFieldTouched(
                                                "customer_first_name",
                                                true
                                            );
                                            props.setFieldTouched(
                                                "customer_last_name",
                                                true
                                            );
                                            props.setFieldTouched(
                                                "customer_mobile",
                                                true
                                            );
                                            props.setFieldTouched(
                                                "total",
                                                true
                                            );
                                            return res;
                                        });
                                    }}
                                    errors={props.errors}
                                />
                                <Dialog
                                    open={openDialog}
                                    onClose={handleCloseDialog}
                                >
                                    <DialogTitle>
                                        {"Do You Want To Print?"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            You Can print the Rental information
                                            if you want
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseDialog}>
                                            No
                                        </Button>
                                        <Button
                                            onClick={() =>
                                                handlePrint(
                                                    props.values.driver_license
                                                )
                                            }
                                            autoFocus
                                        >
                                            Print
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            </Form>
                        );
                    }}
                </Formik>
            </Box>

            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    variant={"filled"}
                    onClose={handleClose}
                    severity={stat ? "success" : "error"}
                    sx={{ width: "40ch" }}
                >
                    {msg}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default CarRent;
