import React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { CollapseMenu } from "../Components/CollapseMenu.component";
import { BedRounded, CarRentalRounded, Money } from "@mui/icons-material";
import SideBarMenus from "./SideBarMenus.json";
import { useLocation } from "react-router";
import Logo from "../Assets/img/logo.png";

const SideBar = () => {
    const location = useLocation();
    return (
        <Paper className="SideBar">
            <Box className="LogoContainer">
                <img
                    src={Logo}
                    alt="OYO Hotel"
                    height={"50ch"}
                    width={"110ch"}
                    style={{ alignSelf: "center" }}
                />
                <hr />
                <Typography>Blytheville OYO Hotel</Typography>
            </Box>
            <Box height={"2ch"} />
            <CollapseMenu
                key={1}
                icon={<BedRounded />}
                menus={SideBarMenus.Room}
                pathname={SideBarMenus.RoomPath}
                title="Room"
                open={Boolean(
                    location.pathname.match(
                        new RegExp(`${SideBarMenus.Room}`.replaceAll(/,/g, "|"))
                    )
                )}
            />
            <CollapseMenu
                key={2}
                icon={<CarRentalRounded />}
                menus={SideBarMenus.Car}
                pathname={SideBarMenus.CarPath}
                title="Car"
                open={Boolean(
                    location.pathname.match(
                        new RegExp(`${SideBarMenus.Car}`.replaceAll(/,/g, "|"))
                    )
                )}
            />
            <CollapseMenu
                key={3}
                icon={<Money />}
                menus={SideBarMenus.Accounting}
                pathname={SideBarMenus.AccountingPath}
                title="Accounting"
                open={Boolean(
                    location.pathname.match(
                        new RegExp(
                            `${SideBarMenus.Accounting}`.replaceAll(/,/g, "|")
                        )
                    )
                )}
            />
        </Paper>
    );
};

export default SideBar;
