import {
    FormControl,
    FormLabel,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";

import { AccountingURL } from "../../Utils/config";
import { get } from "../../Utils/fetcher";
import { Box } from "@mui/system";

const ViewAccounting = () => {
    const [rows, setRows] = useState([]);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [loading, setLoading] = useState(false);

    const columns = [
        {
            field: "type_of_transaction",
            headerName: "Type Of Transaction",
        },
        {
            field: "source_of_income",
            headerName: "Source Of Income",
        },
        {
            field: "room_number",
            headerName: "Room Number",
            valueGetter: (params) => {
                if (params.row.booking?.rooms?.room_number === undefined) {
                    return "N/A";
                }
                return `${params.row.booking?.rooms?.room_number}`;
            },
        },
        {
            field: "plate_number",
            headerName: "Plate Number",
            valueGetter: (params) => {
                if (params.row.carRent?.cars?.plate_number === undefined) {
                    return "N/A";
                }
                return `${params.row.carRent?.cars?.plate_number}`;
            },
        },
        {
            field: "type_of_expense",
            headerName: "Type Of Expense",
        },
        {
            field: "date",
            headerName: "Date",
        },
        {
            field: "vendor",
            headerName: "Vendor",
        },
        {
            field: "customer_name",
            headerName: "Customer Name",
            width: 200,
        },
        {
            field: "payment_type",
            headerName: "Payment Type",
        },
        {
            field: "amount",
            headerName: "Amount",
        },
        {
            field: "note",
            headerName: "Note",
            width: 300,
        },
    ];

    useEffect(() => {
        if (rows.length === 0) {
            setLoading(true);
        }
        get(AccountingURL).then((res) => {
            setLoading(false);
            if (res.data) {
                if (startDate && endDate) {
                    setRows(
                        res.data.filter((accounting) => {
                            return (
                                new Date(accounting.date).getTime() >
                                    new Date(startDate).getTime() &&
                                new Date(accounting.date).getTime() <
                                    new Date(endDate).getTime()
                            );
                        })
                    );
                } else setRows(res.data);
            }
        });
    }, [endDate, rows.length, startDate]);

    return (
        <Paper className="Body">
            <Typography variant="h5" fontWeight={"bold"}>
                View Income/Expenses
            </Typography>
            <Box style={{ display: "flex", flexDirection: "row", gap: "2ch" }}>
                <FormControl style={{ width: "30%" }}>
                    <FormLabel>Start Date</FormLabel>
                    <TextField
                        fullWidth
                        size="small"
                        name="start_date"
                        variant="outlined"
                        type="date"
                        value={startDate}
                        onChange={(e) => {
                            setStartDate(e.target.value);
                        }}
                    />
                </FormControl>
                <FormControl style={{ width: "30%" }}>
                    <FormLabel>End Date</FormLabel>
                    <TextField
                        fullWidth
                        size="small"
                        name="search"
                        variant="outlined"
                        type="date"
                        value={endDate}
                        onChange={(e) => {
                            setEndDate(e.target.value);
                        }}
                    />
                </FormControl>
            </Box>

            <div style={{ height: 550, width: "100%" }}>
                <DataGrid
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    pageSize={50}
                    rowsPerPageOptions={[50]}
                    onRowClick={(selected) => {
                        // setSelectedRow(selected);
                    }}
                    components={{
                        Toolbar: GridToolbarExport,
                    }}
                />
            </div>
        </Paper>
    );
};

export default ViewAccounting;
