import {
    Alert,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Modal,
    Snackbar,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { CleanedURL, VacateURL } from "../../Utils/config";
import { patcher, url } from "../../Utils/fetcher";
import EditBookRoom from "./EditBookRoom.page";

const RoomAction = ({ selectedRow, toggleHandler }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [stat, setStat] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [msg, setMsg] = useState("");

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const vacate = () => {
        setLoading(true);
        patcher(VacateURL, {
            room_number: selectedRow.rooms?.room_number,
        }).then((res) => {
            setLoading(false);
            toggleHandler();
            if (res.data?.error === 0) {
                setOpen(true);
                setStat(false);
                setMsg("Error Occured");
            } else if (res?.data?.success === 0) {
                setOpen(true);
                setStat(true);
                setMsg("Room Vacated");
                // actions.resetForm();
            }
        });
    };

    const cleaned = () => {
        setLoading(true);
        patcher(CleanedURL, {
            room_number: selectedRow.rooms?.room_number,
        }).then((res) => {
            setLoading(false);
            toggleHandler();
            if (res.data?.error === 0) {
                setOpen(true);
                setStat(false);
                setMsg("Error Occured");
            } else if (res?.data?.success === 0) {
                setOpen(true);
                setStat(true);
                setMsg("Room Cleaned");
            }
        });
    };

    const handleEditModalClose = () => {
        setEditModal(false);
    };

    return (
        <>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 100,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Modal open={editModal} onClose={handleEditModalClose}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: "80%",
                        minWidth: "70%",
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <EditBookRoom selectedRow={selectedRow} toggleHandler={toggleHandler}/>
                </Box>
            </Modal>
            <Box
                sx={{
                    p: 2,
                    border: "1px dashed grey",
                    borderRadius: "5px",
                }}
                id="print-content"
                className="PrintContainer"
            >
                <Box className="PrintColumn">
                    <Typography>Customer Information</Typography>
                    <Divider variant="fullWidth" />
                    <Box height={"3ch"} />
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>ID -</Typography>
                        <Typography>
                            {selectedRow.customers.customer_id
                                ? selectedRow.customers.customer_id
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            First Name -
                        </Typography>
                        <Typography>
                            {selectedRow.customers.customer_first_name
                                ? selectedRow.customers.customer_first_name
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Last Name -</Typography>
                        <Typography>
                            {selectedRow.customers.customer_last_name
                                ? selectedRow.customers.customer_last_name
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Mobile -</Typography>
                        <Typography>
                            {selectedRow.customers.customer_mobile
                                ? selectedRow.customers.customer_mobile
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>E-mail -</Typography>
                        <Typography>
                            {selectedRow.customers.customer_email
                                ? selectedRow.customers.customer_email
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        {selectedRow.customers.driver_license && (
                            <Grid item xs={12}>
                                <img
                                    src={
                                        url +
                                        selectedRow.customers.driver_license
                                    }
                                    alt=""
                                    style={{
                                        maxHeight: "20ch",
                                        maxWidth: "30ch",
                                    }}
                                />
                            </Grid>
                        )}
                    </Box>
                </Box>
                <Divider orientation="vertical" />
                <Box className="PrintColumn">
                    <Typography>Room Information</Typography>
                    <Divider variant="fullWidth" />
                    <Box height={"3ch"} />
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Room Type -</Typography>
                        <Typography>
                            {selectedRow.rooms.room_type
                                ? selectedRow.rooms.room_type
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Room Number -
                        </Typography>
                        <Typography>
                            {selectedRow.rooms.room_number
                                ? selectedRow.rooms.room_number
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Number Of Nights
                        </Typography>
                        <Typography>
                            {selectedRow.number_of_nights
                                ? selectedRow.number_of_nights
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Number Of Person
                        </Typography>
                        <Typography>
                            {selectedRow.number_of_person
                                ? selectedRow.number_of_person
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Number Of Pet
                        </Typography>
                        <Typography>
                            {selectedRow.number_of_pet
                                ? selectedRow.number_of_pet
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Check In Date -
                        </Typography>
                        <Typography>
                            {selectedRow.check_in_date
                                ? selectedRow.check_in_date
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Check Out Date -
                        </Typography>
                        <Typography>
                            {selectedRow.check_out_date
                                ? selectedRow.check_out_date
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Rate -</Typography>
                        <Typography>
                            {selectedRow.rate ? selectedRow.rate : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Late Checking Or Early Checking Fee -
                        </Typography>
                        <Typography>
                            {selectedRow.late_or_early_fee
                                ? selectedRow.late_or_early_fee
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Booking Type -
                        </Typography>
                        <Typography>
                            {selectedRow.booking_type
                                ? selectedRow.booking_type
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Payment Type -
                        </Typography>
                        <Typography>
                            {selectedRow.payment_type
                                ? selectedRow.payment_type
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Tenure -</Typography>
                        <Typography>
                            {selectedRow.rooms.tenure
                                ? selectedRow.rooms.tenure
                                : "-"}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box height={"3ch"} />
            <Divider variant="fullWidth" />
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    sx={{ mt: 2, mr: 2 }}
                    variant={"contained"}
                    onClick={() => {
                        setEditModal(true);
                    }}
                >
                    Update
                </Button>
                {selectedRow.rooms.status === "Occupied" ? (
                    <Button
                        variant="outlined"
                        color="error"
                        sx={{ mt: 2 }}
                        onClick={() => {
                            vacate();
                        }}
                    >
                        Vacate
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="success"
                        sx={{ mt: 2 }}
                        onClick={() => {
                            cleaned();
                        }}
                    >
                        Cleaned
                    </Button>
                )}
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <Alert
                    variant={"filled"}
                    onClose={handleClose}
                    severity={stat ? "success" : "error"}
                    sx={{ width: "40ch" }}
                >
                    {msg}
                </Alert>
            </Snackbar>
        </>
    );
};

export default RoomAction;
