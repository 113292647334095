import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
    Divider,
    FormControl,
    FormLabel,
    Snackbar,
    Alert,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FormStepper } from "../../Components/FormStepper.component";
import * as Yup from "yup";

import { UpdateBookRoomURL } from "../../Utils/config";
import { FileCaller, get, url } from "../../Utils/fetcher";
import TextFieldWrapper from "../../Components/TextFieldWrapper.component";
import { FileUploadRounded } from "@mui/icons-material";
import { enums } from "../../Utils/constants";
import { DateTimePicker } from "@mui/lab";

const EditBookRoom = ({ selectedRow, toggleHandler }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [stat, setStat] = useState(false);
    const [msg, setMsg] = useState("");

    const uploadRef = useRef();

    const steps = ["Customer Information", "Room Information"];
    const INITIAL_VALUES = {
        customer_id: selectedRow.customers?.customer_id,
        customer_first_name: selectedRow.customers?.customer_first_name,
        customer_last_name: selectedRow.customers?.customer_last_name,
        customer_mobile: selectedRow.customers?.customer_mobile,
        customer_email: selectedRow.customers?.customer_email,
        driver_license: selectedRow.customers.driver_license,
        street: selectedRow.customers?.street,
        city: selectedRow.customers?.city,
        state: selectedRow.customers?.state,
        zip_code: selectedRow.customers?.zip_code,
        room_type: selectedRow.rooms?.room_type,
        room_number: selectedRow.rooms?.room_number,
        number_of_nights: selectedRow.number_of_nights,
        number_of_person: selectedRow.number_of_person,
        number_of_pet: selectedRow.number_of_pet,
        check_in_date: selectedRow.check_in_date,
        check_out_date: selectedRow.check_out_date,
        rate: selectedRow.rate,
        late_or_early_fee: selectedRow.late_or_early_fee,
        booking_type: selectedRow.booking_type,
        payment_type: selectedRow.payment_type,
        total: selectedRow.total,
        tenure: selectedRow.rooms?.tenure,
        room_id: selectedRow.rooms?.id,
    };

    const FORM_VALIDATION = Yup.object().shape({
        customer_id: Yup.string().required("*Required"),
        customer_first_name: Yup.string().required("*Required"),
        customer_last_name: Yup.string().required("*Required"),
        customer_mobile: Yup.string().required("*Required"),
        customer_email: Yup.string().email().nullable(),
        room_type: Yup.string().required("*Required"),
        room_number: Yup.string().required("*Required"),
        number_of_nights: Yup.number().required("*Require"),
        number_of_person: Yup.number().required("*Require"),
        number_of_pet: Yup.number().nullable(),
        check_in_date: Yup.string().required("*Required"),
        check_out_date: Yup.string().required("*Required"),
        rate: Yup.string().required("*Required"),
        late_or_early_fee: Yup.string().nullable(),
        booking_type: Yup.string().nullable(),
        payment_type: Yup.string().nullable(),
        total: Yup.string().required("*Required"),
        tenure: Yup.string().nullable(),
    });

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        FileCaller(UpdateBookRoomURL, data).then((res) => {
            setLoading(false);
            toggleHandler();
            if (res.data?.error === 0) {
                setOpen(true);
                setStat(false);
                setMsg(res?.data?.msg || "Error Occured");
            } else if (res?.data?.success === 0) {
                setOpen(true);
                setStat(true);
                setMsg(res?.data?.msg || "Room Updated");
                // setOpenDialog(true);
                // actions.resetForm();
            }
        });
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    //#region === Form Components
    const CustomerInformation = ({ fileFunc }) => {
        const [file, setFile] = useState();
        let fileUrl = selectedRow.customers.driver_license;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="customer_id"
                            label="ID"
                            variant="outlined"
                        />
                    </Grid>
                    <Box width={"100%"} />
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="customer_first_name"
                            label="First Name"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="customer_last_name"
                            label="Last Name"
                            variant="outlined"
                        />
                    </Grid>
                    <Box width={"100%"} />
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="customer_email"
                            label="E-mail"
                            variant="outlined"
                            type="email"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="customer_mobile"
                            label="Mobile"
                            variant="outlined"
                            type="tel"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="street"
                            label="Street"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="city"
                            label="City"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="state"
                            label="State"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="zip_code"
                            label="Zip Code"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            data-testid="uploadButton"
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                uploadRef.current.click();
                            }}
                        >
                            Upload
                            {
                                <FileUploadRounded
                                    style={{ paddingLeft: "2ch" }}
                                />
                            }
                        </Button>
                        <input
                            name="file"
                            hidden
                            ref={uploadRef}
                            type="file"
                            accept="image/png,image/jpeg"
                            onChange={(e) => {
                                setFile(e.target.files[0]);
                                fileFunc(e.target.files[0]);
                            }}
                        />
                    </Grid>
                    {(file || fileUrl) && (
                        <Grid item xs={12}>
                            <img
                                onClick={() => {
                                    uploadRef.current.click();
                                }}
                                src={
                                    file
                                        ? URL.createObjectURL(file)
                                        : url + fileUrl
                                }
                                alt=""
                                style={{
                                    maxHeight: "20ch",
                                    maxWidth: "30ch",
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </>
        );
    };
    const RoomInformation = ({
        calculateTotal,
        calculateRate,
        roomTypeFunc,
        roomNumberFunc,
        checkInDateFunc,
        checkOutDateFunc,
        rateFunc,
        bookingTypeFunc,
        paymentTypeFunc,
        tenureFunc,
        totalFunc,
    }) => {
        const [roomType, setRoomType] = useState(1);
        const [roomNumber, setRoomNumber] = useState(1);
        const [availableRooms, setAvailableRooms] = useState([]);
        const [availableRoomNumbers, setAvailableRoomNumbers] = useState([]);
        const [availableRoomTypes, setAvailableRoomTypes] = useState([]);
        const [tenure, setTenure] = useState(1);
        const [bookingType, setBookingType] = useState(1);
        const [paymentType, setPaymentType] = useState(1);
        const [checkInDate, setCheckInDate] = useState(
            selectedRow.check_in_date
        );
        const [checkOutDate, setCheckOutDate] = useState(
            selectedRow.check_out_date
        );
        const [total, setTotal] = useState(selectedRow.total);
        const [rate, setRate] = useState(selectedRow.rate);

        useEffect(() => {
            let activeRoom = [];
            let activeType = [];
            get("/room").then((res) => {
                res.data.forEach((room) => {
                    if (room.status === "Active") {
                        activeRoom.push({
                            room_number: room.room_number,
                            room_type: room.room_type,
                            room_id: room.id,
                        });
                        activeType.push(room.room_type);
                    }
                });
                setAvailableRooms(activeRoom);
                setAvailableRoomTypes([...new Set(activeType)]);
            });
        }, [availableRooms.length]);

        useEffect(() => {
            setAvailableRoomNumbers((prev) => {
                return availableRooms
                    .filter(
                        (availableRoom) =>
                            availableRoom.room_type ===
                            availableRoomTypes[roomType - 1]
                    )
                    .map((value) => value.room_number);
            });
        }, [availableRoomTypes, availableRooms, roomType]);

        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            disabled
                            select
                            name="room_type"
                            label="Room Type"
                            variant="outlined"
                            options={[
                                selectedRow.rooms.room_type,
                                ...availableRoomTypes,
                            ].filter((v, i, a) => a.indexOf(v) === i)}
                            value={roomType}
                            onChange={(e) => {
                                setRoomType(e.target.value);
                            }}
                            onBlur={() => {
                                roomTypeFunc(availableRoomTypes[roomType - 1]);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            disabled
                            select
                            name="room_number"
                            label="Room Number"
                            variant="outlined"
                            options={[
                                selectedRow.rooms.room_number,
                                ...availableRoomNumbers,
                            ].filter((v, i, a) => a.indexOf(v) === i)}
                            value={roomNumber}
                            onChange={(e) => {
                                setRoomNumber(e.target.value);
                            }}
                            onBlur={() => {
                                let id = null;
                                availableRooms.forEach((room) => {
                                    if (
                                        room.room_number ===
                                        availableRoomNumbers[roomNumber - 1]
                                    ) {
                                        id = room.room_id;
                                    }
                                });
                                roomNumberFunc(
                                    availableRoomNumbers[roomNumber - 1],
                                    id
                                );
                            }}
                        />
                    </Grid>
                    <Box width={"100%"} />
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            defaultValue={1}
                            name="number_of_nights"
                            label="Number Of Nights"
                            variant="outlined"
                            type="number"
                            onBlur={(e) => {
                                let date = new Date(
                                    String(checkInDate)
                                ).getTime();
                                let cod = new Date(
                                    date + e.target.value * 24 * 60 * 60 * 1000
                                );
                                setCheckOutDate(cod);
                                setCheckOutDate((prevDate) => {
                                    return new Date(prevDate).setHours(11, 0);
                                });
                                calculateTotal(setTotal, rate);
                                checkOutDateFunc(
                                    new Date(cod)
                                        .toISOString()
                                        .replace(/T|Z|\.\d{3}/g, " ")
                                        .trim()
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            defaultValue={1}
                            name="number_of_person"
                            label="Number Of Person"
                            variant="outlined"
                            type="number"
                            onBlur={() => {
                                calculateTotal(setTotal, rate);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            defaultValue={0}
                            name="number_of_pet"
                            label="Number Of Pet"
                            variant="outlined"
                            type="number"
                            onBlur={() => {
                                calculateTotal(setTotal, rate);
                            }}
                        />
                    </Grid>
                    <Box width={"100%"} />
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <FormLabel>Check In Date</FormLabel>
                            <DateTimePicker
                                renderInput={(props) => (
                                    <TextFieldWrapper
                                        {...props}
                                        variant="outlined"
                                        name="check_in_date"
                                    />
                                )}
                                value={checkInDate}
                                onChange={(newValue) => {
                                    setCheckInDate(newValue);
                                }}
                                onBlur={() => {
                                    checkInDateFunc(
                                        new Date(checkInDate)
                                            .toISOString()
                                            .replace(/T|Z|\.\d{3}/g, " ")
                                            .trim()
                                    );
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <FormLabel>Check Out Date</FormLabel>
                            <DateTimePicker
                                renderInput={(props) => (
                                    <TextFieldWrapper
                                        {...props}
                                        variant="outlined"
                                        name="check_out_date"
                                    />
                                )}
                                value={checkOutDate}
                                onChange={(newValue) => {
                                    setCheckOutDate(newValue);
                                }}
                                onBlur={() => {
                                    checkOutDateFunc(
                                        new Date(checkOutDate)
                                            .toISOString()
                                            .replace(/T|Z|\.\d{3}/g, " ")
                                            .trim()
                                    );
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Box width={"100%"} />
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            value={rate}
                            name="rate"
                            label="Rate"
                            variant="outlined"
                            type="number"
                            onChange={(e) => {
                                setRate(e.target.value);
                            }}
                            onBlur={() => {
                                calculateTotal(setTotal, rate);
                                rateFunc(rate);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="late_or_early_fee"
                            label="Late Checking Or Early Checking Fee"
                            variant="outlined"
                            type="number"
                            onBlur={() => {
                                calculateTotal(setTotal, rate);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            select
                            name="tenure"
                            label="Tenure"
                            variant="outlined"
                            options={[
                                selectedRow.rooms?.tenure,
                                ...enums.Tenure,
                            ].filter((v, i, a) => a.indexOf(v) === i)}
                            value={tenure}
                            onChange={(e) => {
                                setTenure(e.target.value);
                            }}
                            onBlur={() => {
                                tenureFunc(tenure);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            select
                            name="booking_type"
                            label="Booking Type"
                            variant="outlined"
                            options={[
                                selectedRow.booking_type,
                                ...enums.BookingType,
                            ].filter((v, i, a) => a.indexOf(v) === i)}
                            value={bookingType}
                            onChange={(e) => {
                                setBookingType(e.target.value);
                            }}
                            onBlur={() => {
                                bookingTypeFunc(bookingType);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            select
                            name="payment_type"
                            label="Payment Type"
                            variant="outlined"
                            options={[
                                selectedRow.payment_type,
                                ...enums.PaymentType,
                            ].filter((v, i, a) => a.indexOf(v) === i)}
                            value={paymentType}
                            onChange={(e) => {
                                setPaymentType(e.target.value);
                            }}
                            onBlur={() => {
                                paymentTypeFunc(paymentType);
                            }}
                        />
                    </Grid>
                    <Box width={"100%"} />
                    <Divider variant={"fullWidth"} />
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            value={total}
                            name="total"
                            label="Total"
                            variant="outlined"
                            type="number"
                            onChange={(e) => {
                                setTotal(e.target.value);
                            }}
                            onBlur={() => {
                                calculateRate(setRate, total);
                                totalFunc(total);
                                rateFunc(rate);
                            }}
                        />
                    </Grid>
                </Grid>
            </>
        );
    };
    //#endregion

    return (
        <Paper className={"Container"} elevation={0}>
            <Typography variant="h5" fontWeight={"bold"}>
                Book Rooms
            </Typography>
            <Box className={"Container--Outlined"}>
                <Formik
                    validateOnMount
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.check_in_date = new Date(values.check_in_date)
                            .toISOString()
                            .replace(/T|Z|\.\d{3}/g, " ")
                            .trim();
                        values.check_out_date = new Date(
                            values.check_out_date
                        ).setDate(
                            new Date(values.check_out_date).getDate() + 1
                        );
                        values.check_out_date = new Date(values.check_out_date)
                            .toISOString()
                            .replace(/T|Z|\.\d{3}/g, " ")
                            .trim();
                        values.checkIn_id = selectedRow.id;
                        values.customer_db_id = selectedRow.customers?.id;
                        /* Then create a new FormData obj */
                        let formData = new FormData();
                        /* append input field values to formData */
                        for (let value in values) {
                            formData.append(value, values[value]);
                        }

                        // for (let property of formData.entries()) {
                        //     console.log(property[0], property[1]);
                        // }

                        formSubmitHandler(formData, actions);
                    }}
                >
                    {(props) => {
                        //#region
                        const calculateTotal = (setTotal, rate) => {
                            let val = props.values;
                            let petTot = 10 * val.number_of_pet;
                            let subTotal =
                                (parseFloat(rate) +
                                    parseFloat(val.late_or_early_fee) +
                                    petTot) *
                                parseInt(val.number_of_nights);
                            setTotal(
                                Math.round(
                                    (subTotal * 1.13 + Number.EPSILON) * 100
                                ) / 100
                            );
                            props.values.total =
                                Math.round(
                                    (subTotal * 1.13 + Number.EPSILON) * 100
                                ) / 100;
                        };

                        const calculateRate = (setRate, total) => {
                            let val = props.values;
                            let fee = total / 1.13;
                            let petTot = 10 * val.number_of_pet;
                            let rate =
                                (fee - petTot - val.late_or_early_fee) /
                                val.number_of_nights;
                            setRate(
                                Math.round((rate + Number.EPSILON) * 100) / 100
                            );
                            props.values.rate =
                                Math.round((rate + Number.EPSILON) * 100) / 100;
                        };

                        const roomTypeFunc = (roomType) => {
                            props.setValues({
                                ...props.values,
                                room_type: roomType,
                            });
                        };

                        const roomNumberFunc = (roomNumber, id) => {
                            props.setValues({
                                ...props.values,
                                room_number: roomNumber,
                                room_id: id,
                            });
                        };
                        const checkInDateFunc = (checkInDate) => {
                            props.values.check_in_date = checkInDate;
                        };
                        const checkOutDateFunc = (checkOutDate) => {
                            props.values.check_out_date = checkOutDate;
                        };
                        const rateFunc = (rate) => {
                            props.values.rate = parseFloat(rate);
                        };
                        const bookingTypeFunc = (bookingType) => {
                            props.setValues({
                                ...props.values,
                                booking_type: bookingType,
                            });
                        };
                        const paymentTypeFunc = (paymentType) => {
                            props.setValues({
                                ...props.values,
                                payment_type: paymentType,
                            });
                        };
                        const tenureFunc = (tenure) => {
                            props.setValues({
                                ...props.values,
                                tenure: tenure,
                            });
                        };
                        const totalFunc = (total) => {
                            props.values.total = parseFloat(total);
                            calculateRate(null, total);
                        };

                        const fileFunc = (file) => {
                            props.values.driver_license = file;
                        };
                        //#endregion

                        return (
                            <Form>
                                <FormStepper
                                    key={1}
                                    loading={loading}
                                    page={[
                                        <CustomerInformation
                                            fileFunc={fileFunc}
                                        />,
                                        <RoomInformation
                                            key={"RoomInfo"}
                                            calculateTotal={calculateTotal}
                                            calculateRate={calculateRate}
                                            roomTypeFunc={roomTypeFunc}
                                            roomNumberFunc={roomNumberFunc}
                                            checkInDateFunc={checkInDateFunc}
                                            checkOutDateFunc={checkOutDateFunc}
                                            rateFunc={rateFunc}
                                            bookingTypeFunc={bookingTypeFunc}
                                            paymentTypeFunc={paymentTypeFunc}
                                            tenureFunc={tenureFunc}
                                            totalFunc={totalFunc}
                                        />,
                                    ]}
                                    steps={steps}
                                    finishButton={() => {
                                        if (
                                            props.values.number_of_nights === 1
                                        ) {
                                            let date = new Date();
                                            date.setDate(date.getDate() + 1);
                                            date.setHours(11, 0);
                                            props.setValues({
                                                ...props.values,
                                                check_out_date: date
                                                    .toISOString()
                                                    .replace(
                                                        /T|Z|\.\d{3}/g,
                                                        " "
                                                    )
                                                    .trim(),
                                            });
                                        }
                                    }}
                                    validateFormFunc={() => {
                                        props.validateForm().then((res) => {
                                            props.setFieldTouched(
                                                "customer_id",
                                                true
                                            );
                                            props.setFieldTouched(
                                                "customer_first_name",
                                                true
                                            );
                                            props.setFieldTouched(
                                                "customer_last_name",
                                                true
                                            );
                                            props.setFieldTouched(
                                                "customer_mobile",
                                                true
                                            );
                                            props.setFieldTouched(
                                                "total",
                                                true
                                            );
                                            return res;
                                        });
                                    }}
                                    errors={props.errors}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </Box>

            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    variant={"filled"}
                    onClose={handleClose}
                    severity={stat ? "success" : "error"}
                    sx={{ width: "40ch" }}
                >
                    {msg}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default EditBookRoom;
