export const Cars = "/car";
export const CheckInURL = "/checkIn";
export const BookRoomURL = "/bookRoom";
export const UpdateBookRoomURL = "/updateBookRoom";
export const CarRentURL = "/rentCar";
export const UpdateCarRentURL = "/updateRentCar";
export const CarURL = "/carRent";
export const VacateURL = "/vacate";
export const CleanedURL = "/cleaned";
export const VacateCarURL = "/vacateCar";
export const CleanedCarURL = "/cleanedCar";
export const AccountingURL = "/accounting";
export const CustomerURL = "/customer";
export const CheckInHistoryURL = "/getCheckInHistory";
export const CarHistoryURL = "/getCarRentHistory";
