import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Logo from "../../Assets/img/logo.png";
import { url } from "../../Utils/fetcher";

const PrintRoom = () => {
    const { state } = useLocation();
    const { values, file } = state;
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (loaded) {
            window.print();
        }
    }, [loaded]);

    return (
        <div className="BookRoomPrint">
            <div className="Header">
                <img
                    src={Logo}
                    alt="OYO Hotel"
                    height={"50ch"}
                    width={"110ch"}
                    style={{ alignSelf: "center" }}
                />
                <Typography variant="h4" fontWeight={"bolder"}>
                    Receipt
                </Typography>
                <Box
                    style={{
                        flexDirection: "column",
                        justifyContent: "center",
                        display: "flex",
                    }}
                >
                    <Typography>
                        <strong>Address </strong>
                        <br />
                        357 S Diision Street,
                        <br />
                        Bliytheville, AR 72315
                    </Typography>
                    <Typography>
                        <strong>Phone </strong>
                        <br />
                        870 763 4588
                    </Typography>
                </Box>
            </div>

            <Box height={"3ch"} />
            <Box
                sx={{
                    p: 2,
                    border: "1px dashed grey",
                    borderRadius: "5px",
                }}
                id="print-content"
                className="PrintContainer"
            >
                <Box className="PrintColumn">
                    <Typography>Customer Information</Typography>
                    <Divider variant="fullWidth" />
                    <Box height={"3ch"} />
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>ID -</Typography>
                        <Typography>
                            {values.customer_id ? values.customer_id : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            First Name -
                        </Typography>
                        <Typography>
                            {values.customer_first_name
                                ? values.customer_first_name
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Last Name -</Typography>
                        <Typography>
                            {values.customer_last_name
                                ? values.customer_last_name
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Mobile -</Typography>
                        <Typography>
                            {values.customer_mobile
                                ? values.customer_mobile
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>E-mail -</Typography>
                        <Typography>
                            {values.customer_email
                                ? values.customer_email
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        {file && (
                            <Grid item xs={12}>
                                <img
                                    src={
                                        typeof file === "string"
                                            ? url + file
                                            : URL.createObjectURL(file)
                                    }
                                    alt=""
                                    style={{
                                        maxHeight: "20ch",
                                        maxWidth: "30ch",
                                    }}
                                    onLoad={() => {
                                        setLoaded(true);
                                    }}
                                />
                            </Grid>
                        )}
                    </Box>
                </Box>
                <Divider orientation="vertical" />
                <Box className="PrintColumn">
                    <Typography>Room Information</Typography>
                    <Divider variant="fullWidth" />
                    <Box height={"3ch"} />
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Room Type -</Typography>
                        <Typography>
                            {values.room_type ? values.room_type : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Room Number -
                        </Typography>
                        <Typography>
                            {values.room_number ? values.room_number : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Number Of Nights
                        </Typography>
                        <Typography>
                            {values.number_of_nights
                                ? values.number_of_nights
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Number Of Person
                        </Typography>
                        <Typography>
                            {values.number_of_person
                                ? values.number_of_person
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Number Of Pet
                        </Typography>
                        <Typography>
                            {values.number_of_pet ? values.number_of_pet : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Check In Date -
                        </Typography>
                        <Typography>
                            {values.check_in_date ? values.check_in_date : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Check Out Date -
                        </Typography>
                        <Typography>
                            {values.check_out_date
                                ? values.check_out_date
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Rate -</Typography>
                        <Typography>
                            {values.rate ? values.rate : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Late Checking Or Early Checking Fee -
                        </Typography>
                        <Typography>
                            {values.late_or_early_fee
                                ? values.late_or_early_fee
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Booking Type -
                        </Typography>
                        <Typography>
                            {values.booking_type ? values.booking_type : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Payment Type -
                        </Typography>
                        <Typography>
                            {values.payment_type ? values.payment_type : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Tenure -</Typography>
                        <Typography>
                            {values.tenure ? values.tenure : "-"}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box height={"3ch"} />
            <Divider variant="fullWidth" />
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                }}
            >
                <Typography fontWeight={"bold"} variant="h5">
                    Total :
                </Typography>
                <Typography fontWeight={"bold"} variant="h5">
                    {values.total ? parseFloat(values.total).toFixed(2) : " -"}
                </Typography>
            </Box>
        </div>
    );
};

export default PrintRoom;
