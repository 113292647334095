import axios from "axios";

let response;
// export const baseURL = `http://127.0.0.1:8000/api`;
// export const url = `http://127.0.0.1:8000`;
export const baseURL = `https://hotelback.trilokinc.com/api`;
export const url = `https://hotelback.trilokinc.com/`;

/**
 *
 * @param {string} url URL String
 * @param {object} body Object of data to be sent
 * @returns data or error
 */
export const Post = async (url, body) => {
    // console.log(url);
    await axios
        .post(baseURL + url, body)
        .then((res) => {
            response = res;
        })
        .catch((err) => {
            if (err.response) {
                response = err.response.data;
            } else {
                console.log("Connection Error -- " + err);
            }
        });
    return response;
};

/**
 *
 * @param {string} url URL String
 * @param {object} body Object of data to be sent
 * @returns data or error
 */
export const FileCaller = async (url, body) => {
    await axios
        .post(baseURL + url, body, {
            baseURL,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((res) => {
            response = res;
        })
        .catch((err) => {
            if (err.response) {
                response = err.response.data;
            } else {
                console.log("Connection Error -- " + err);
            }
        });
    return response;
};

/**
 *
 * @param {string} url URL String
 * @param {object} body Object of data to be sent
 * @returns data or error
 */
export const filePatcher = async (url, body) => {
    await axios
        .patch(baseURL + url, body, {
            baseURL,
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then((res) => {
            response = res;
        })
        .catch((err) => {
            if (err.response) {
                response = err.response.data;
            } else {
                console.log("Connection Error -- " + err);
            }
        });
    return response;
};
/**
 * 
 * @param {string} url URL String
 * @param {object} body Object of data to be sent
 * @returns data or error
 */
export const get = async (url, body) => {
    const a = JSON.stringify(body);
    await axios
        .get(baseURL + url, { params: { body: a } })
        .then((res) => {
            response = res;
        })
        .catch((err) => {
            console.log("Connection Error -- " + err);
        });
    return response;
};
/**
 *
 * @param {string} url URL String
 * @param {object} body Object of data to be sent
 * @returns data or error
 */
export const patcher = async (url, body) => {
    await axios
        .patch(baseURL + url, body)
        .then((res) => {
            response = res;
        })
        .catch((err) => {
            if (err.response) {
                response = err.response.data;
            } else {
                console.log("Connection Error -- " + err);
            }
        });
    return response;
};

/**
 *
 * @param {string} url URL String
 * @param {object} body Object of data to be sent
 * @returns data or error
 */
export const deleter = async (url, body) => {
    await axios
        .delete(baseURL + url, { data: body })
        .then((res) => {
            response = res;
        })
        .catch((err) => {
            if (err.response) {
                response = err.response.data;
                console.log(err.response.data.error);
            } else {
                console.log("Connection Error -- " + err);
            }
        });
    return response;
};
