import {
    Alert,
    Backdrop,
    Box,
    Button,
    CircularProgress,
    Divider,
    Grid,
    Modal,
    Snackbar,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { CleanedCarURL, VacateCarURL } from "../../Utils/config";
import { patcher, url } from "../../Utils/fetcher";
import EditCarRent from "./EditCarRent.page";

const CarAction = ({ selectedRow, toggleHandler }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [stat, setStat] = useState(false);
    const [msg, setMsg] = useState("");
    const [editModal, setEditModal] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    const vacate = () => {
        setLoading(true);
        patcher(VacateCarURL, {
            id: selectedRow.cars?.id,
        }).then((res) => {
            setLoading(false);
            toggleHandler();
            if (res.data?.error === 0) {
                setOpen(true);
                setStat(false);
                setMsg("Error Occured");
            } else if (res?.data?.success === 0) {
                setOpen(true);
                setStat(true);
                setMsg("Car Vacated");
                // actions.resetForm();
            }
        });
    };

    const handleEditModalClose = () => {
        setEditModal(false);
    };

    const cleaned = () => {
        setLoading(true);
        patcher(CleanedCarURL, {
            id: selectedRow.cars?.id,
        }).then((res) => {
            setLoading(false);
            toggleHandler();
            if (res.data?.error === 0) {
                setOpen(true);
                setStat(false);
                setMsg("Error Occured");
            } else if (res?.data?.success === 0) {
                setOpen(true);
                setStat(true);
                setMsg("Car Cleaned");
                // actions.resetForm();
            }
        });
    };
    return (
        <>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: 100,
                }}
                open={loading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Modal open={editModal} onClose={handleEditModalClose}>
                <Box
                    sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        maxWidth: "80%",
                        minWidth: "70%",
                        bgcolor: "background.paper",
                        border: "2px solid #000",
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <EditCarRent
                        selectedRow={selectedRow}
                        toggleHandler={toggleHandler}
                    />
                </Box>
            </Modal>
            <Box
                sx={{
                    p: 2,
                    border: "1px dashed grey",
                    borderRadius: "5px",
                }}
                id="print-content"
                className="PrintContainer"
            >
                <Box className="PrintColumn">
                    <Typography>Customer Information</Typography>
                    <Divider variant="fullWidth" />
                    <Box height={"3ch"} />
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>ID -</Typography>
                        <Typography>
                            {selectedRow.customers.customer_id
                                ? selectedRow.customers.customer_id
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            First Name -
                        </Typography>
                        <Typography>
                            {selectedRow.customers.customer_first_name
                                ? selectedRow.customers.customer_first_name
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Last Name -</Typography>
                        <Typography>
                            {selectedRow.customers.customer_last_name
                                ? selectedRow.customers.customer_last_name
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Mobile -</Typography>
                        <Typography>
                            {selectedRow.customers.customer_mobile
                                ? selectedRow.customers.customer_mobile
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>E-mail -</Typography>
                        <Typography>
                            {selectedRow.customers.customer_email
                                ? selectedRow.customers.customer_email
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        {selectedRow.customers.driver_license && (
                            <Grid item xs={12}>
                                <img
                                    src={
                                        url +
                                        selectedRow.customers.driver_license
                                    }
                                    alt=""
                                    style={{
                                        maxHeight: "20ch",
                                        maxWidth: "30ch",
                                    }}
                                />
                            </Grid>
                        )}
                    </Box>
                </Box>
                <Divider orientation="vertical" />
                <Box className="PrintColumn">
                    <Typography>Room Information</Typography>
                    <Divider variant="fullWidth" />
                    <Box height={"3ch"} />
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Car Plate</Typography>

                        <Typography>
                            {selectedRow.cars.plate_number
                                ? selectedRow.cars.plate_number
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Number Of Days
                        </Typography>
                        <Typography>
                            {selectedRow.number_of_days
                                ? selectedRow.number_of_days
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Check Out Mileage
                        </Typography>
                        <Typography>
                            {selectedRow.check_out_mileage
                                ? selectedRow.check_out_mileage
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Gas Money</Typography>
                        <Typography>
                            {selectedRow.gas_money
                                ? selectedRow.gas_money
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>
                            Pick Up Date
                        </Typography>
                        <Typography>
                            {selectedRow.pick_up_date
                                ? selectedRow.pick_up_date
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Return Date</Typography>
                        <Typography>
                            {selectedRow.return_date
                                ? selectedRow.return_date
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Rent Type</Typography>
                        <Typography>
                            {selectedRow.booking_type
                                ? selectedRow.booking_type
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Rate -</Typography>
                        <Typography>
                            {selectedRow.payment_type
                                ? selectedRow.payment_type
                                : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Amount -</Typography>
                        <Typography>
                            {selectedRow.amount ? selectedRow.amount : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Tax -</Typography>
                        <Typography>
                            {selectedRow.tax ? selectedRow.tax : "-"}
                        </Typography>
                    </Box>
                    <Box className="FormValues">
                        <Typography fontWeight={"bold"}>Total -</Typography>
                        <Typography>
                            {selectedRow.total ? selectedRow.total : "-"}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box height={"3ch"} />
            <Divider variant="fullWidth" />
            <Box
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-end",
                }}
            >
                <Button
                    sx={{ mt: 2, mr: 2 }}
                    variant={"contained"}
                    onClick={() => {
                        setEditModal(true);
                    }}
                >
                    Update
                </Button>
                {selectedRow.cars.status === "Rented" ? (
                    <Button
                        variant="outlined"
                        color="error"
                        sx={{ mt: 2 }}
                        onClick={() => {
                            vacate();
                        }}
                    >
                        Vacate
                    </Button>
                ) : (
                    <Button
                        variant="contained"
                        color="success"
                        sx={{ mt: 2 }}
                        onClick={() => {
                            cleaned();
                        }}
                    >
                        Cleaned
                    </Button>
                )}
            </Box>
            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
            >
                <Alert
                    variant={"filled"}
                    onClose={handleClose}
                    severity={stat ? "success" : "error"}
                    sx={{ width: "40ch" }}
                >
                    {msg}
                </Alert>
            </Snackbar>
        </>
    );
};

export default CarAction;
