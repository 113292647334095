import { ChevronLeft, SearchRounded } from "@mui/icons-material";
import {
    IconButton,
    InputAdornment,
    Paper,
    TextField,
    Typography,
    Box,
    Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import { CarHistoryURL } from "../../Utils/config";
import { get } from "../../Utils/fetcher";
import { useNavigate } from "react-router";

const ViewAllCar = () => {
    const [rows, setRows] = useState([]);
    const [filter, setFilter] = useState("");
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const columns = [
        {
            field: "plate_number",
            headerName: "Plate Number",
            valueGetter: (params) => {
                return `${params.row.cars?.plate_number}`;
            },
        },
        {
            field: "first_name",
            headerName: "First Name",
            valueGetter: (params) => {
                return `${params.row.customers?.customer_first_name}`;
            },
        },
        {
            field: "last_name",
            headerName: "Last Name",
            valueGetter: (params) => {
                return `${params.row.customers?.customer_last_name}`;
            },
        },
        {
            field: "check_out_mileage",
            headerName: "Check Out Mileage",
        },
        {
            field: "pick_up_date",
            headerName: "Pick Up Date",
        },
        {
            field: "return_date",
            headerName: "Return Date",
        },
        {
            field: "mobile_number",
            headerName: "Mobile Number",
            valueGetter: (params) => {
                return `${params.row.customers?.customer_mobile}`;
            },
        },
    ];

    useEffect(() => {
        if (rows.length === 0) {
            setLoading(true);
        }
        get(CarHistoryURL, { filter }).then((res) => {
            setLoading(false);
            if (res.data) {
                let carsThatNeedAction = res.data.filter((value) => {
                    if (filter) {
                        return value.cars?.plate_number.match(filter);
                    } else return value;
                });
                setRows(carsThatNeedAction);
            }
        });
    }, [filter, rows.length]);

    return (
        <Paper className="Body">
            <Typography variant="h5" fontWeight={"bold"}>
                View Cars
            </Typography>
            <Box
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                }}
            >
                <TextField
                    size="small"
                    name="search"
                    label="Search Room"
                    variant="filled"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment
                                position="start"
                                style={{ marginTop: "-2px" }}
                            >
                                <IconButton
                                    onClick={(e) => {
                                        setFilter(search);
                                    }}
                                >
                                    <SearchRounded />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                    style={{ width: "50%" }}
                />
                <Button
                    startIcon={<ChevronLeft />}
                    onClick={() => {
                        navigate("/Car/List");
                    }}
                >
                    Go Back
                </Button>
            </Box>
            <div style={{ height: 500, width: "100%", overflowY: "scroll" }}>
                <DataGrid
                    loading={loading}
                    rows={rows}
                    columns={columns}
                    autoHeight={true}
                    pageSize={50}
                    rowsPerPageOptions={[8]}
                    onRowClick={(selected) => {
                        // setSelectedRow(selected);
                    }}
                />
            </div>
        </Paper>
    );
};

export default ViewAllCar;
