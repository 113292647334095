import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
    Divider,
    FormControl,
    FormLabel,
    Snackbar,
    Alert,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { FormStepper } from "../../Components/FormStepper.component";
import * as Yup from "yup";

import { UpdateCarRentURL } from "../../Utils/config";
import { FileCaller, get, url } from "../../Utils/fetcher";
import TextFieldWrapper from "../../Components/TextFieldWrapper.component";
import { FileUploadRounded } from "@mui/icons-material";
import { enums } from "../../Utils/constants";
import { DateTimePicker } from "@mui/lab";

const EditCarRent = ({ selectedRow, toggleHandler }) => {
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [stat, setStat] = useState(false);
    const [msg, setMsg] = useState("");

    const uploadRef = useRef();

    const steps = ["Customer Information", "Car Information"];
    const INITIAL_VALUES = {
        customer_id: selectedRow.customers?.customer_id,
        customer_first_name: selectedRow.customers?.customer_first_name,
        customer_last_name: selectedRow.customers?.customer_last_name,
        customer_mobile: selectedRow.customers?.customer_mobile,
        customer_email: selectedRow.customers?.customer_email,
        driver_license: selectedRow.customers?.driver_license,
        street: selectedRow.customers?.street,
        city: selectedRow.customers?.city,
        state: selectedRow.customers?.state,
        zip_code: selectedRow.customers?.zip_code,
        car_plate: selectedRow.cars?.car_plate,
        number_of_days: selectedRow.customers?.number_of_days,
        check_out_mileage: selectedRow.check_out_mileage,
        gas_money: selectedRow.customers?.gas_money,
        pick_up_date: selectedRow.pick_up_date,
        return_date: selectedRow.return_date,
        tenure: selectedRow.tenure,
        payment_type: selectedRow.payment_type,
        amount: selectedRow.amount,
        tax: selectedRow.tax,
        total: selectedRow.total,
        car_id: selectedRow.cars?.id,
    };

    const FORM_VALIDATION = Yup.object().shape({
        customer_id: Yup.string().required("*Required"),
        customer_first_name: Yup.string().required("*Required"),
        customer_last_name: Yup.string().required("*Required"),
        customer_mobile: Yup.string().required("*Required"),
        customer_email: Yup.string().email().nullable(),
        check_out_mileage: Yup.string().nullable(),
        number_of_days: Yup.string().required("*Required"),
        gas_money: Yup.string().nullable(),
        pick_up_date: Yup.string().required("*Required"),
        return_date: Yup.string().required("*Required"),
        amount: Yup.string().required("*Required"),
        tenure: Yup.string().nullable(),
        payment_type: Yup.string().nullable(),
        tax: Yup.string().required("*Required"),
        total: Yup.string().required("*Required"),
    });

    const formSubmitHandler = (data, actions) => {
        setLoading(true);
        FileCaller(UpdateCarRentURL, data).then((res) => {
            setLoading(false);
            toggleHandler();
            if (res.data?.error === 0) {
                setOpen(true);
                setStat(false);
                setMsg(res.data?.msg || "Error Occured");
            } else if (res?.data?.success === 0) {
                setOpen(true);
                setStat(true);
                setMsg(res.data?.msg || "Car Rented");
                // setOpenDialog(true);
                // actions.resetForm();
            }
        });
    };
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };

    //#region === Form Components
    const CustomerInformation = ({ fileFunc }) => {
        const [file, setFile] = useState();
        let fileUrl = selectedRow.customers.driver_license;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="customer_id"
                            label="ID"
                            variant="outlined"
                        />
                    </Grid>
                    <Box width={"100%"} />
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="customer_first_name"
                            label="First Name"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="customer_last_name"
                            label="Last Name"
                            variant="outlined"
                        />
                    </Grid>
                    <Box width={"100%"} />
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="customer_email"
                            label="E-mail"
                            variant="outlined"
                            type="email"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="customer_mobile"
                            label="Mobile"
                            variant="outlined"
                            type="tel"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="street"
                            label="Street"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="city"
                            label="City"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="state"
                            label="State"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="zip_code"
                            label="Zip Code"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            data-testid="uploadButton"
                            color="primary"
                            variant="contained"
                            onClick={() => {
                                uploadRef.current.click();
                            }}
                        >
                            Upload
                            {
                                <FileUploadRounded
                                    style={{ paddingLeft: "2ch" }}
                                />
                            }
                        </Button>
                        <input
                            name="file"
                            hidden
                            ref={uploadRef}
                            type="file"
                            accept="image/png,image/jpeg"
                            onChange={(e) => {
                                setFile(e.target.files[0]);
                                fileFunc(e.target.files[0]);
                            }}
                        />
                    </Grid>
                    {(file || fileUrl) && (
                        <Grid item xs={12}>
                            <img
                                onClick={() => {
                                    uploadRef.current.click();
                                }}
                                src={
                                    file
                                        ? URL.createObjectURL(file)
                                        : url + fileUrl
                                }
                                alt=""
                                style={{
                                    maxHeight: "20ch",
                                    maxWidth: "30ch",
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </>
        );
    };
    const CarInformation = ({
        calculateTotal,
        calculateAmount,
        carPlateFunc,
        pickUpDateFunc,
        returnDateFunc,
        amountFunc,
        bookingTypeFunc,
        paymentTypeFunc,
        tenureFunc,
        totalFunc,
    }) => {
        const [carPlate, setCarPlate] = useState(1);
        const [availableCars, setAvailableCars] = useState(1);
        const [availableCarPlates, setAvailableCarPlates] = useState([]);
        const [tenure, setTenure] = useState(1);
        const [paymentType, setPaymentType] = useState(1);
        const [bookingType, setBookingType] = useState(1);
        const [pickUpDate, setPickUpDate] = useState(selectedRow.pick_up_date);

        const [returnDate, setReturnDate] = useState(selectedRow.return_date);
        const [total, setTotal] = useState(selectedRow.total);
        const [amount, setAmount] = useState(selectedRow.amount);

        useEffect(() => {
            let activeCar = [];
            let availableCar = [];
            get("/car").then((res) => {
                res.data.forEach((car) => {
                    if (car.status === "Available") {
                        activeCar.push({
                            plate_number: car.plate_number,
                            car_id: car.id,
                        });
                        availableCar.push(car.plate_number);
                    }
                });
                setAvailableCars(activeCar);
                setAvailableCarPlates(availableCar);
            });
        }, [availableCars.length]);

        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            select
                            name="car_plate"
                            label="Car Plate"
                            variant="outlined"
                            options={[
                                selectedRow.cars?.plate_number,
                                ...availableCarPlates,
                            ].filter((v, i, a) => a.indexOf(v) === i)}
                            value={carPlate}
                            onChange={(e) => {
                                setCarPlate(e.target.value);
                            }}
                            onBlur={() => {
                                let id = null;
                                availableCars.forEach((car) => {
                                    if (
                                        car.plate_number ===
                                        availableCarPlates[carPlate - 1]
                                    ) {
                                        id = car.car_id;
                                    }
                                });
                                carPlateFunc(
                                    availableCarPlates[carPlate - 1],
                                    id
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="check_out_mileage"
                            label="Check Out Mileage"
                            variant="outlined"
                            type="number"
                        />
                    </Grid>
                    <Box width={"100%"} />
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            defaultValue={1}
                            name="number_of_days"
                            label="Number Of Days"
                            variant="outlined"
                            type="number"
                            onBlur={(e) => {
                                setReturnDate((prevDate) => {
                                    return new Date(
                                        pickUpDate +
                                            e.target.value * 24 * 60 * 60 * 1000
                                    );
                                });
                                setReturnDate((prevDate) => {
                                    return new Date(prevDate).setHours(11, 0);
                                });
                                calculateTotal(setTotal, amount);
                                returnDateFunc(
                                    new Date(returnDate)
                                        .toISOString()
                                        .replace(/T|Z|\.\d{3}/g, " ")
                                        .trim()
                                );
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            name="gas_money"
                            label="Gas Money"
                            variant="outlined"
                            type="number"
                        />
                    </Grid>
                    <Box width={"100%"} />
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <FormLabel>Pick Up Date</FormLabel>
                            <DateTimePicker
                                renderInput={(props) => (
                                    <TextFieldWrapper
                                        {...props}
                                        variant="outlined"
                                        name="pick_up_date"
                                    />
                                )}
                                value={pickUpDate}
                                onChange={(newValue) => {
                                    setPickUpDate(newValue);
                                }}
                                onBlur={() => {
                                    pickUpDateFunc(
                                        new Date(pickUpDate)
                                            .toISOString()
                                            .replace(/T|Z|\.\d{3}/g, " ")
                                            .trim()
                                    );
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <FormLabel>Return Date</FormLabel>
                            <DateTimePicker
                                renderInput={(props) => (
                                    <TextFieldWrapper
                                        {...props}
                                        variant="outlined"
                                        name="return_date"
                                    />
                                )}
                                value={returnDate}
                                onChange={(newValue) => {
                                    setReturnDate(newValue);
                                }}
                                onBlur={() => {
                                    returnDateFunc(
                                        new Date(returnDate)
                                            .toISOString()
                                            .replace(/T|Z|\.\d{3}/g, " ")
                                            .trim()
                                    );
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Box width={"100%"} />
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            value={amount}
                            name="amount"
                            label="Amount"
                            variant="outlined"
                            type="number"
                            onChange={(e) => {
                                setAmount(e.target.value);
                            }}
                            onBlur={() => {
                                calculateTotal(setTotal, amount);
                                amountFunc(amount);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            defaultValue={0.26}
                            name="tax"
                            label="Tax"
                            variant="outlined"
                            type="number"
                            onBlur={() => {
                                calculateTotal(setTotal, amount);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            select
                            name="tenure"
                            label="Tenure"
                            variant="outlined"
                            options={[
                                selectedRow.tenure,
                                ...enums.Tenure,
                            ].filter((v, i, a) => a.indexOf(v) === i)}
                            value={tenure}
                            onChange={(e) => {
                                setTenure(e.target.value);
                            }}
                            onBlur={() => {
                                tenureFunc(tenure);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            select
                            name="booking_type"
                            label="Booking Type"
                            variant="outlined"
                            options={[
                                selectedRow.booking_type,
                                ...enums.BookingType,
                            ].filter((v, i, a) => a.indexOf(v) === i)}
                            value={bookingType}
                            onChange={(e) => {
                                setBookingType(e.target.value);
                            }}
                            onBlur={() => {
                                bookingTypeFunc(bookingType);
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            select
                            name="payment_type"
                            label="Payment Type"
                            variant="outlined"
                            options={[
                                selectedRow.payment_type,
                                ...enums.PaymentType,
                            ].filter((v, i, a) => a.indexOf(v) === i)}
                            value={paymentType}
                            onChange={(e) => {
                                setPaymentType(e.target.value);
                            }}
                            onBlur={() => {
                                paymentTypeFunc(paymentType);
                            }}
                        />
                    </Grid>
                    <Box width={"100%"} />
                    <Divider variant={"fullWidth"} />
                    <Grid item xs={4}>
                        <TextFieldWrapper
                            value={total}
                            name="total"
                            label="Total"
                            variant="outlined"
                            type="number"
                            onChange={(e) => {
                                setTotal(e.target.value);
                            }}
                            onBlur={() => {
                                calculateAmount(setAmount, total);
                                totalFunc(total);
                                amountFunc(amount);
                            }}
                        />
                    </Grid>
                </Grid>
            </>
        );
    };
    //#endregion

    return (
        <Paper className={"Container"} elevation={0}>
            <Typography variant="h5" fontWeight={"bold"}>
                Rent Cars
            </Typography>
            <Box className={"Container--Outlined"}>
                <Formik
                    validateOnMount
                    initialValues={{ ...INITIAL_VALUES }}
                    validationSchema={FORM_VALIDATION}
                    onSubmit={(values, actions) => {
                        values.pick_up_date = new Date(values.pick_up_date)
                            .toISOString()
                            .replace(/T|Z|\.\d{3}/g, " ")
                            .trim();
                        values.return_date = new Date(
                            values.return_date
                        ).setDate(new Date(values.return_date).getDate() + 1);
                        values.return_date = new Date(values.return_date)
                            .toISOString()
                            .replace(/T|Z|\.\d{3}/g, " ")
                            .trim();
                        values.carRent_id = selectedRow.id;
                        values.customer_db_id = selectedRow.customers?.id;
                        /* Then create a new FormData obj */
                        let formData = new FormData();
                        /* append input field values to formData */
                        for (let value in values) {
                            formData.append(value, values[value]);
                        }

                        // for (let property of formData.entries()) {
                        //     console.log(property[0], property[1]);
                        // }

                        formSubmitHandler(formData, actions);
                    }}
                >
                    {(props) => {
                        const calculateTotal = (setTotal, amount) => {
                            let val = props.values;
                            let taxTotal =
                                val.amount * val.number_of_days * val.tax;
                            let subTotal =
                                val.amount * val.number_of_days + taxTotal;
                            setTotal(
                                Math.round((subTotal + Number.EPSILON) * 100) /
                                    100
                            );
                            props.values.total =
                                Math.round((subTotal + Number.EPSILON) * 100) /
                                100;
                        };

                        const calculateAmount = (setAmount, total) => {
                            let val = props.values;
                            let tax = val.tax + 1;
                            let fee = total / tax;
                            let rate = fee / val.number_of_days;
                            setAmount(
                                Math.round(
                                    (parseFloat(rate) + Number.EPSILON) * 100
                                ) / 100
                            );
                            props.values.amount =
                                Math.round((rate + Number.EPSILON) * 100) / 100;
                        };

                        const carPlateFunc = (carPlate, id) => {
                            props.setValues({
                                ...props.values,
                                car_plate: carPlate,
                                car_id: id,
                            });
                        };
                        const pickUpDateFunc = (pickUpDate) => {
                            props.setValues({
                                ...props.values,
                                pick_up_date: pickUpDate,
                            });
                        };
                        const returnDateFunc = (returnDate) => {
                            props.setValues({
                                ...props.values,
                                return_date: returnDate,
                            });
                        };
                        const amountFunc = (amount) => {
                            props.values.amount = parseFloat(amount);
                        };
                        const bookingTypeFunc = (bookingType) => {
                            props.setValues({
                                ...props.values,
                                booking_type: bookingType,
                            });
                        };
                        const paymentTypeFunc = (paymentType) => {
                            props.setValues({
                                ...props.values,
                                payment_type: paymentType,
                            });
                        };
                        const tenureFunc = (tenure) => {
                            props.setValues({
                                ...props.values,
                                tenure: tenure,
                            });
                        };

                        const totalFunc = (total) => {
                            props.values.total = parseFloat(total);
                            calculateAmount(null, total);
                        };

                        const fileFunc = (file) => {
                            props.values.driver_license = file;
                        };

                        return (
                            <Form>
                                <FormStepper
                                    key={1}
                                    loading={loading}
                                    page={[
                                        <CustomerInformation
                                            fileFunc={fileFunc}
                                        />,
                                        <CarInformation
                                            calculateTotal={calculateTotal}
                                            calculateAmount={calculateAmount}
                                            carPlateFunc={carPlateFunc}
                                            pickUpDateFunc={pickUpDateFunc}
                                            returnDateFunc={returnDateFunc}
                                            amountFunc={amountFunc}
                                            bookingTypeFunc={bookingTypeFunc}
                                            paymentTypeFunc={paymentTypeFunc}
                                            tenureFunc={tenureFunc}
                                            totalFunc={totalFunc}
                                        />,
                                    ]}
                                    steps={steps}
                                    finishButton={() => {
                                        if (props.values.number_of_days === 1) {
                                            let date = new Date();
                                            date.setDate(date.getDate() + 1);
                                            date.setHours(11, 0);
                                            props.setValues({
                                                ...props.values,
                                                return_date: date
                                                    .toISOString()
                                                    .replace(
                                                        /T|Z|\.\d{3}/g,
                                                        " "
                                                    )
                                                    .trim(),
                                            });
                                        } else {
                                            let cod = new Date(
                                                props.values.pick_up_date +
                                                    props.values
                                                        .number_of_days *
                                                        24 *
                                                        60 *
                                                        60 *
                                                        1000
                                            ).setHours(11, 0);

                                            props.values.return_date = new Date(
                                                cod
                                            )
                                                .toISOString()
                                                .replace(/T|Z|\.\d{3}/g, " ")
                                                .trim();
                                        }
                                    }}
                                    validateFormFunc={() => {
                                        props.validateForm().then((res) => {
                                            props.setFieldTouched(
                                                "customer_id",
                                                true
                                            );
                                            props.setFieldTouched(
                                                "customer_first_name",
                                                true
                                            );
                                            props.setFieldTouched(
                                                "customer_last_name",
                                                true
                                            );
                                            props.setFieldTouched(
                                                "customer_mobile",
                                                true
                                            );
                                            props.setFieldTouched(
                                                "total",
                                                true
                                            );
                                            return res;
                                        });
                                    }}
                                    errors={props.errors}
                                />
                            </Form>
                        );
                    }}
                </Formik>
            </Box>

            <Snackbar
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    variant={"filled"}
                    onClose={handleClose}
                    severity={stat ? "success" : "error"}
                    sx={{ width: "40ch" }}
                >
                    {msg}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default EditCarRent;
